import { OrderItemFieldsFragment } from 'core/lib/graphql/fragments/_gen/order-item-fields.fragment';
import { ProductFieldsFragment } from 'core/lib/graphql/store/_gen/product';
import { PaymentMethod } from 'core/lib/graphql/types.d';
import { CartProductItemType } from '../components/my-cart-types';
import { isEventsEnabled } from '../constants/events';
import { captureException } from '../sentry';

/** Google Analytics 4 Checkout Item Parameters
(https://developers.google.com/gtagjs/reference/ga4-events#begin_checkout_item) */
export interface GA4Product {
  /** The ID of the item. (e.g. SKU_12345) */
  item_id: string;
  /** The name of the item. (e.g. Stan and Friends Tee) */
  item_name: string;
  /** A product affiliation to designate a supplying company or brick and mortar store location.
  Event-level and item-level affiliation parameters are independent. (e.g. Google Store) */
  affiliation?: string;
  /** The coupon name/code associated with the item.
  Event-level and item-level coupon parameters are independent. (e.g. SUMMER_FUN) */
  coupon?: string;
  /** The currency, in 3-letter ISO 4217 format. (e.g. USD)
  If set, event-level currency is ignored.
  Multiple currencies per event is not supported. Each item should set the same currency. */
  currency?: string;
  /** The monetary discount value associated with the item. (e.g. 2.22) */
  discount?: number;
  /** The index/position of the item in a list. (e.g. 5) */
  index?: number;
  /** The brand of the item. (e.g. Google) */
  item_brand?: string;
  /** The category of the item. If used as part of a category hierarchy or taxonomy
   then this will be the first category. (e.g. Apparel) */
  item_category?: string;
  /** The ID of the list in which the item was presented to the user. (e.g. related_products)
  If set, event-level item_list_id is ignored.
  If not set, event-level item_list_id is used, if present. */
  item_list_id?: string;
  /** The name of the list in which the item was presented to the user. (e.g. Related products)
  If set, event-level item_list_name is ignored.
  If not set, event-level item_list_name is used, if present. */
  item_list_name?: string;
  /** The item variant or unique code or description for additional item details/options.
  (e.g. green) */
  item_variant?: string;
  /** The monetary price of the item, in units of the specified currency parameter. (e.g. 9.99) */
  price?: number;
  /** Item quantity. (e.g. 1) */
  quantity?: number;
}

export enum ShippingTier {
  Local = 'LOCAL',
  Mandae = 'MANDAE',
}

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}

const mapProducts =
  (coupon?: string, item_list_name?: string, item_list_id?: string) =>
  (it: ProductFieldsFragment) => ({
    item_id: it.id,
    item_name: it.name,
    coupon,
    item_list_name,
    item_list_id,
    item_brand: 'Veroo',
    item_category: it.kind,
    price: it.price,
    currency: 'BRL',
    quantity: it.quantity,
  });

export class GTagEvent {
  static event(event: string, extra?: Record<string, unknown>) {
    if (isEventsEnabled && typeof window !== 'undefined') {
      try {
        window.dataLayer.push({ event, ...extra });
      } catch (e) {
        captureException(e);
      }
    } else {
      // eslint-disable-next-line
      console.log('[TAGMANAGER]', event, extra);
    }
  }

  static setVar(key: string, value: unknown) {
    if (isEventsEnabled && typeof window !== 'undefined') {
      try {
        window.dataLayer.push({ [key]: value });
      } catch (e) {
        captureException(e);
      }
    } else {
      // eslint-disable-next-line
      console.log('[TAGMANAGER]', key, value);
    }
  }

  static productFromCartProduct(
    it: OrderItemFieldsFragment | CartProductItemType,
    idx?: number,
  ): GA4Product {
    return {
      item_id: it.product.id,
      item_name: it.product.name,
      currency: 'BRL',
      discount: it.discount,
      index: idx,
      item_brand: 'Veroo',
      item_category: it.product.kind,
      price: it.price,
      quantity: it.quantity,
    };
  }

  static productsFromCart(
    products: OrderItemFieldsFragment[] | CartProductItemType[],
  ): GA4Product[] {
    return products.map(GTagEvent.productFromCartProduct);
  }

  static productFromProductFields(
    it: { product: ProductFieldsFragment; quantity: number; gridId?: string },
    idx?: number,
  ): GA4Product {
    const gridOption = it?.product?.grids?.find(grid => grid.id === it.gridId);
    return {
      item_id: it.product.id,
      item_name: it.product.name,
      currency: 'BRL',
      discount: (it.product.originalPrice || it.product.price) - it.product.price,
      index: idx,
      item_brand: 'Veroo',
      item_category: it.product.kind,
      item_variant:
        gridOption != null
          ? `${gridOption.value.name} - ${gridOption.value.option.name}`
          : undefined,
      price: it.product.price,
      quantity: it.quantity,
    };
  }

  static productsFromProductFieldsList(
    items: { product: ProductFieldsFragment; quantity: number; gridId?: string }[],
  ): GA4Product[] {
    return items.map(GTagEvent.productFromProductFields);
  }

  static loadAnalytics() {
    GTagEvent.event('load-analytics');
    GTagEvent.event('optimize.activate');
  }

  static loadMarketing() {
    GTagEvent.event('load-marketing');
  }

  static login(method?: string) {
    GTagEvent.setVar('loginMethod', method);
    GTagEvent.event('login');
  }

  static signUp(method?: string) {
    GTagEvent.setVar('signUpMethod', method);
    GTagEvent.event('sign_up');
  }

  static share(method?: string) {
    GTagEvent.setVar('shareMethod', method);
    GTagEvent.event('share');
  }

  static search(search_term: string) {
    GTagEvent.setVar('searchSearchTerm', search_term);
    GTagEvent.event('search', { search_term });
  }

  static searchResults(search_term: string) {
    GTagEvent.event('view_search_results', { search_term });
  }

  static lead(value: number) {
    GTagEvent.setVar('generateLeadCurrency', 'BRL');
    GTagEvent.setVar('generateLeadValue', value);
    GTagEvent.event('generate_lead');
  }

  // E-commerce
  static viewItemList(
    item_list_name: string,
    item_list_id: string,
    items: ProductFieldsFragment[],
    coupon?: string,
  ) {
    GTagEvent.setVar('viewItemListItemListId', item_list_id);
    GTagEvent.setVar('viewItemListItemListName', item_list_name);
    GTagEvent.setVar(
      'viewItemListItems',
      items.map(mapProducts(coupon, item_list_name, item_list_id)),
    );
    GTagEvent.event('view_item_list');
  }

  static viewItem(item: ProductFieldsFragment, coupon?: string) {
    GTagEvent.setVar('viewItemCoupon', coupon);
    GTagEvent.setVar('viewItemCurrency', 'BRL');
    // GTagEvent.setVar('viewItemItemCategory', value);
    GTagEvent.setVar(
      'viewItemItems',
      GTagEvent.productsFromProductFieldsList([{ product: item, quantity: 1 }]),
    );
    GTagEvent.setVar('viewItemValue', item.price);
    GTagEvent.event('view_item');
  }

  static addToCart(
    item: ProductFieldsFragment,
    quantity: number,
    gridId?: string,
    coupon?: string,
  ) {
    GTagEvent.setVar('addToCartCoupon', coupon);
    GTagEvent.setVar('addToCartCurrency', 'BRL');
    GTagEvent.setVar(
      'addToCartItems',
      GTagEvent.productsFromProductFieldsList([{ product: item, quantity, gridId }]),
    );
    GTagEvent.setVar('addToCartValue', item.price);
    GTagEvent.event('add_to_cart');
  }

  static removeFromCart(item: OrderItemFieldsFragment, coupon?: string) {
    GTagEvent.setVar('removeFromCartCoupon', coupon);
    GTagEvent.setVar('removeFromCartCurrency', 'BRL');
    GTagEvent.setVar('removeFromCartItems', GTagEvent.productsFromCart([item]));
    GTagEvent.setVar('removeFromCartValue', item.price);
    GTagEvent.event('remove_from_cart');
  }

  static beginCheckout(value: number, items: OrderItemFieldsFragment[], coupon?: string) {
    GTagEvent.setVar('beginCheckoutCoupon', coupon);
    GTagEvent.setVar('beginCheckoutCurrency', 'BRL');
    GTagEvent.setVar('beginCheckoutItems', GTagEvent.productsFromCart(items));
    GTagEvent.setVar('beginCheckoutValue', value);
    GTagEvent.event('begin_checkout');
  }

  static shippingInfo(
    value: number,
    items: CartProductItemType[],
    shipping_tier?: ShippingTier,
    coupon?: string,
  ) {
    GTagEvent.setVar('addShippingInfoCoupon', coupon);
    GTagEvent.setVar('addShippingInfoCurrency', 'BRL');
    GTagEvent.setVar('addShippingInfoItems', GTagEvent.productsFromCart(items));
    GTagEvent.setVar('addShippingInfoValue', value);
    GTagEvent.setVar('addShippingInfoShippingTier', shipping_tier);
    GTagEvent.event('add_shipping_info');
  }

  static paymentInfo(
    value: number,
    items: CartProductItemType[],
    payment_type?: PaymentMethod,
    coupon?: string,
  ) {
    GTagEvent.setVar('addPaymentInfoCoupon', coupon);
    GTagEvent.setVar('addPaymentInfoCurrency', 'BRL');
    GTagEvent.setVar('addPaymentInfoItems', GTagEvent.productsFromCart(items));
    GTagEvent.setVar('addPaymentInfoValue', value);
    GTagEvent.setVar('addPaymentInfoPaymentType', payment_type);
    GTagEvent.event('add_payment_info');
  }

  // eslint-disable-next-line max-params
  static purchase(
    value: number,
    items: OrderItemFieldsFragment[],
    transaction_id: string,
    shipping?: number,
    coupon?: string,
    isSubscription?: boolean,
  ) {
    GTagEvent.setVar('purchaseCoupon', coupon);
    GTagEvent.setVar('purchaseCurrency', 'BRL');
    GTagEvent.setVar('purchaseItems', GTagEvent.productsFromCart(items));
    GTagEvent.setVar('purchaseValue', value);
    GTagEvent.setVar('purchaseTransactionId', transaction_id);
    GTagEvent.setVar('purchaseShipping', shipping);
    GTagEvent.event(isSubscription ? 'subscription' : 'purchase');
  }

  static checkoutStep(step?: number, step_name?: string) {
    GTagEvent.setVar('step', step);
    GTagEvent.setVar('step_name', step_name);
    GTagEvent.event('checkout_step');
  }
}
