import { ApolloProvider } from '@apollo/client';
import { CacheProvider, Global, ThemeProvider as StyledThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { useApollo } from '@web/common/apollo';
import { GeneralSnackbar } from '@web/common/components/general-snackbar';
import { Page } from '@web/common/components/get-page-type';
import { DefaultSEO } from '@web/common/components/seo/default-seo';
import { createEmotionCache } from '@web/common/create-emotion-cache';
import { useCookies } from '@web/common/hooks/use-cookies';
import { NProgressStyleCreator, usePageLoader } from '@web/common/hooks/use-next-page-loader';
import '@web/common/styles/app.css';
import { theme } from '@web/common/theme';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { FC } from 'react';

const NProgressStyle = NProgressStyleCreator('#23442f');
const clientSideEmotionCache = createEmotionCache();

type AppPropsWithLayout = AppProps & {
  Component: Page;
  emotionCache: any;
};

const gtm = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WFV6SD2');`;

const CustomApp: FC<AppPropsWithLayout> = ({
  Component,
  pageProps,
  router,
  emotionCache = clientSideEmotionCache,
}) => {
  const apolloClient = useApollo(pageProps);
  usePageLoader();
  useCookies();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => page);
  const host: string =
    (pageProps as any).host ?? (typeof window !== 'undefined' ? window.location.host : '');
  return (
    <ApolloProvider client={apolloClient}>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Global styles={NProgressStyle} />
          <DefaultSEO />
          <StyledThemeProvider theme={theme}>
            <StylesProvider injectFirst>
              {getLayout(<Component {...pageProps} key={router.route} />)}
              <GeneralSnackbar />
              <Script
                src="https://www.google.com/recaptcha/api.js?render=6LfVJ6wnAAAAADDbIudbm4MjWxlRS1gQOMvrog1A"
                strategy="afterInteractive"
                async
              />
              {process.env.NODE_ENV === 'production' && !host.includes('staging') && (
                <>
                  <Script
                    strategy="afterInteractive"
                    src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/c02d0fbd-a5e7-452f-b937-f4cba70c03c8-loader.js"
                  />
                  <Script
                    dangerouslySetInnerHTML={{ __html: gtm }}
                    id="tagmanager"
                    strategy="afterInteractive"
                  />
                </>
              )}
            </StylesProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </CacheProvider>
    </ApolloProvider>
  );
};

export default CustomApp;
