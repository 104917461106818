import { MetaTag, OpenGraph } from 'next-seo/lib/types';

export const seoTitle = 'Veroo';
export const seoDescription = 'Veroo - Cafés por assinatura, no conforto da sua casa.';
export const seoSiteName = 'Veroo';
export const seoUrl = 'https://veroo.com.br';

export const seoOpenGraph: OpenGraph = {
  title: seoTitle,
  description: seoDescription,
  images: [{ url: 'https://veroo.com.br/favicons/android-chrome-512x512.png' }],
  site_name: seoSiteName,
  url: seoUrl,
  type: 'website',
};

export const extraMeta: MetaTag[] = [
  { name: 'msapplication-TileImage', content: '/favicons/ms-tile-144x144.png' },
  { name: 'msapplication-TileColor', content: '#66c7f4' },
  { name: 'application-name', content: seoSiteName },
  { name: 'apple-mobile-web-app-title', content: seoSiteName },
];
