import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export const createApolloCache = () =>
  new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          myOrders: relayStylePagination(['subscription_Isnull']),
        },
      },
      ProductItemType: {
        keyFields: ['product', ['id'], 'productGrid', ['id']],
      },
    },
  });
