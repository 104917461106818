import { useState, useEffect } from 'react';
import NRouter from 'next/router';
import nprogress from 'nprogress';
import { css } from '@emotion/react';

export const NProgressStyleCreator = (color?: string) => css`
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${color ?? 'black'};
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${color ?? 'black'}, 0 0 5px ${color ?? 'black'};
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }
`;

export const usePageLoader = (delay = 200) => {
  const [timer, setTimer] = useState<number | undefined>();
  const routeChangeStart = () => {
    window.clearTimeout(timer);
    setTimer(window.setTimeout(() => nprogress.start(), delay));
  };
  const routeChangeEnd = () => {
    window.clearTimeout(timer);
    nprogress.done();
  };

  useEffect(() => {
    NRouter.events.on('routeChangeStart', routeChangeStart);
    NRouter.events.on('routeChangeComplete', routeChangeEnd);
    NRouter.events.on('routeChangeError', routeChangeEnd);
    nprogress.configure({
      showSpinner: false,
    });
    return () => {
      window.clearTimeout(timer);
      NRouter.events.off('routeChangeStart', routeChangeStart);
      NRouter.events.off('routeChangeComplete', routeChangeEnd);
      NRouter.events.off('routeChangeError', routeChangeEnd);
    };
  });
};
