import { useEffect } from 'react';
import { CookieConsentStorage } from '../cookies/cookies-storage';
import { ExternalEventsFacade } from '../events/external-event';

export const useCookies = () => {
  useEffect(() => {
    const cookie = CookieConsentStorage.getInstance().get() ?? {
      analytics: true,
      marketing: true,
    };
    ExternalEventsFacade.cookieLoad(cookie.analytics, cookie.marketing);
  });
};
