import { ApolloClient, from, NormalizedCacheObject } from '@apollo/client';
import fieldsMap from '@veroo/core/lib/graphql/type-scalars-dict.json';
import { withScalars } from 'apollo-link-scalars';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import introspectionResult from 'core/lib/graphql/schema.json';
import { isDate, parse } from 'date-fns';
import format from 'date-fns/format';
import merge from 'deepmerge';
import { buildClientSchema } from 'graphql';
import fetch from 'isomorphic-unfetch';
import Cookie from 'js-cookie';
import isEqual from 'lodash/isEqual';
import { GetServerSidePropsContext } from 'next';
import { AppTreeType } from 'next/dist/shared/lib/utils';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getAPIEndpoint } from './api';
import { apolloFormatter } from './apollo-formatter';
import { apolloLinkLog } from './apollo-link-log';
import { createApolloCache } from './create-apollo-cache';

const isSSR = typeof window === 'undefined';
const schema = buildClientSchema(introspectionResult as any);
const typesMap = {
  Date: {
    serialize: (parsed?: unknown) => {
      if (typeof parsed === 'string') return parsed;
      return isDate(parsed) ? format(parsed as Date, 'yyyy-MM-dd') : null;
    },
    parseValue: (raw?: unknown): Date | null =>
      typeof raw === 'string' ? parse(raw, 'yyyy-MM-dd', new Date()) : null,
  },
  DateTime: {
    serialize: (parsed?: unknown) => {
      if (typeof parsed === 'string') return parsed;
      return isDate(parsed) ? (parsed as Date).toString() : null;
    },
    parseValue: (raw?: unknown): Date | null => (typeof raw === 'string' ? new Date(raw) : null),
  },
  Decimal: {
    serialize: (parsed?: unknown) => (typeof parsed === 'number' ? parsed.toString() : null),
    parseValue: (raw?: unknown): number | null =>
      typeof raw === 'string' ? parseFloat(raw) : null,
  },
};

const sentryLink = new SentryLink({
  attachBreadcrumbs: { includeVariables: true, includeError: true },
  setTransaction: true,
  setFingerprint: true,
});

const scalarsLink = withScalars({
  removeTypenameFromInputs: true,
  schema,
  typesMap,
  validateEnums: true,
});

const getFetch = (cookie?: any) => (url: string, init: any) =>
  fetch(url, { ...init, headers: { ...init.headers, ...(cookie != null && { cookie }) } });

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

export interface NextApolloContext {
  /**
   * `Component` the tree of the App to use if needing to render separately
   */
  AppTree: AppTreeType;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  me?: any;
}

export type NextApolloServerSideContext = GetServerSidePropsContext & NextApolloContext;

let apolloClient: ApolloClient<any> | null = null;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 */
export const initializeApollo = (initialState?: any, cookie?: any): ApolloClient<any> => {
  if (!isSSR) {
    const session = Cookie.get('session');
    if (session == null) {
      Cookie.set('session', uuidv4(), {
        path: '/',
        sameSite: 'Lax',
        secure: true,
        expires: 30 * 24 * 60 * 60,
      });
    }
  }
  const documentCookie = isSSR ? undefined : document.cookie;
  const internalClient = apolloClient ?? createApolloClient(cookie ?? documentCookie);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = internalClient.extract();
    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    internalClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (isSSR) return internalClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = internalClient;

  return internalClient;
};

const createApolloClient = (cookie?: any) => {
  const f = getFetch(cookie);
  const uri = getAPIEndpoint();
  const uploadLink = createUploadLink({ uri, credentials: 'same-origin', fetch: f }) as any;
  const links = [sentryLink, uploadLink];
  if (!isSSR) {
    links.unshift(scalarsLink);
  }
  if (process.env.NODE_ENV === 'development') {
    links.unshift(apolloLinkLog);
  }
  return new ApolloClient({
    // Disables forceFetch on the server (so queries are only run once)
    ssrMode: isSSR,
    // Only adds the scalars link on the client
    link: from(links),
    cache: createApolloCache(),
  });
};

export const addApolloState = (client: ApolloClient<any>, pageProps: any) => {
  if ('props' in pageProps) {
    return {
      ...pageProps,
      props: { ...pageProps.props, [APOLLO_STATE_PROP_NAME]: client.cache.extract() },
    };
  }
  return pageProps;
};

export const useApollo = (pageProps: any) => {
  const store = useMemo(
    () =>
      initializeApollo(
        pageProps != null &&
          Object.prototype.hasOwnProperty.call(pageProps, APOLLO_STATE_PROP_NAME) &&
          pageProps[APOLLO_STATE_PROP_NAME] != null
          ? apolloFormatter(pageProps[APOLLO_STATE_PROP_NAME], fieldsMap, typesMap)
          : {},
      ),
    [pageProps],
  );
  return store;
};
