import { createTheme } from '@mui/material';

const HEADINGS_FONT_FAMILY = "'Raleway', sans-serif";
const HEADINGS_FONT_WEIGHT = 600;
export const theme = createTheme({
  typography: {
    fontFamily: "'Raleway', sans-serif",
  },
  palette: {
    primary: {
      main: '#FF4F12',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#23442f',
      contrastText: '#FFF',
    },
    text: {
      primary: '#383838',
      // Minimum contrast ration o 7:1. It passes the AAA test for small font-size
      secondary: 'rgba(0, 0, 0, 0.68)',
    },
    success: {
      main: '#4CAF50',
      contrastText: '#FFF',
    },
    warning: {
      main: '#FFBF30',
      contrastText: '#000',
    },
    error: {
      main: '#FF4444',
      contrastText: '#FFF',
    },
    background: {
      paper: 'white',
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          padding: '0.688rem 2rem',
        },
        text: {
          // padding: '6px 16px',
        },
        sizeSmall: {
          fontSize: '0.8125rem',
        },
        root: {
          fontWeight: 'bold',
          fontSize: '1rem',
          textTransform: 'none',
        },
        containedSizeLarge: {
          fontSize: '1rem',
        },
        outlined: {
          borderColor: '#95989A',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        outlined: {
          borderColor: '#95989A',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          'fontSize': '4rem',
          'fontWeight': HEADINGS_FONT_WEIGHT,
          '@media (max-width: 959.95px)': {
            fontSize: '3.25rem',
          },
          '@media (max-width: 695.95px)': {
            fontSize: '2.75rem',
          },
          'fontFamily': HEADINGS_FONT_FAMILY,
        },
        h2: {
          'fontSize': '3rem',
          'fontWeight': HEADINGS_FONT_WEIGHT,
          '@media (max-width: 959.95px)': {
            fontSize: '2.25rem',
          },
          '@media (max-width: 695.95px)': {
            fontSize: '1.75rem',
          },
          'fontFamily': HEADINGS_FONT_FAMILY,
        },
        h3: {
          fontSize: '1.75rem',
          fontWeight: HEADINGS_FONT_WEIGHT,
          fontFamily: HEADINGS_FONT_FAMILY,
        },
        h4: {
          fontSize: '1.5rem',
          fontWeight: HEADINGS_FONT_WEIGHT,
          fontFamily: HEADINGS_FONT_FAMILY,
        },
        h5: {
          fontSize: '1.25rem',
          fontWeight: HEADINGS_FONT_WEIGHT,
          fontFamily: HEADINGS_FONT_FAMILY,
        },
        h6: {
          fontSize: '1.125rem',
          fontWeight: HEADINGS_FONT_WEIGHT,
          fontFamily: HEADINGS_FONT_FAMILY,
        },
        subtitle1: {
          fontWeight: 600,
        },
        subtitle2: {
          fontWeight: 600,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          outline: 'none !important',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: '2rem 0.25rem',
        },
        paperWidthXs: {
          // maxWidth: 'calc(100% - 2rem)',
          '@media (max-width: 695.95px)': {
            maxWidth: 'calc(100% - 1rem) !important',
          },
        },
        paperWidthSm: {
          // maxWidth: 'calc(100% - 2rem)',
          '@media (max-width: 695.95px)': {
            maxWidth: 'calc(100% - 2rem) !important',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflow: 'inherit !important',
        },
      },
    },
  },
});
