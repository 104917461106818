import { ReactNode } from 'react';
import EventEmitter, { Emitter } from 'event-emitter';

export const emitter: Emitter = EventEmitter();

export interface SnackbarOptions {
  autoHideDuration?: number;
  message: string | ReactNode;
  type?: 'default' | 'success' | 'information' | 'danger';
  anchorOrigin?: {
    horizontal: 'center' | 'left' | 'right';
    vertical: 'bottom' | 'top';
  };
}

export const showSnackbar = (options: SnackbarOptions) => {
  emitter.emit('show', options);
};

export const showSuccessSnackbar = (
  message: string,
  autoHideDuration?: number,
  anchorOrigin?: { horizontal: string; vertical: string },
) => {
  emitter.emit('show', { type: 'success', message, autoHideDuration, anchorOrigin });
};

export const showInformationSnackbar = (
  message: string,
  autoHideDuration?: number,
  anchorOrigin?: { horizontal: string; vertical: string },
) => {
  emitter.emit('show', { type: 'information', message, autoHideDuration, anchorOrigin });
};

export const showDangerSnackbar = (
  message: string,
  autoHideDuration?: number,
  anchorOrigin?: { horizontal: string; vertical: string },
) => {
  emitter.emit('show', { type: 'danger', message, autoHideDuration, anchorOrigin });
};
