import { LocalStorageObjectItem } from '../storage/local-storage-object-item';
import { CookieOptions } from './cookies-interfaces';

export const COOKIE_CONSENT_KEY = 'verooCookiesConsent';

export class CookieConsentStorage extends LocalStorageObjectItem<CookieOptions> {
  static instance: CookieConsentStorage;

  static getInstance() {
    if (CookieConsentStorage.instance == null) {
      CookieConsentStorage.instance = new CookieConsentStorage(COOKIE_CONSENT_KEY);
    }
    return CookieConsentStorage.instance;
  }

  acceptCookies() {
    this.set({
      analytics: true,
      consent: true,
      hideCookieBanner: true,
      marketing: true,
    });
  }
}

export const consentStorage = CookieConsentStorage.getInstance();
