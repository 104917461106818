export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: Date;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: Date;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: number;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: string;
  /**
   * The upload of a file.
   *
   * Variables of this type must be set to null in mutations. They will be
   * replaced with a filename from a following multipart part containing a
   * binary file.
   *
   * See: https://github.com/jaydenseric/graphql-multipart-request-spec
   */
  UploadType: any;
};

/** Address input type. */
export type AddressInput = {
  /** The address's state (e.g. `Ribeirão Preto`) */
  city: Scalars['String'];
  /** An optional complement (e.g. `Apto 69 (nice)`) */
  complement?: InputMaybe<Scalars['String']>;
  /** The address's district (e.g. `Jardim Satânico`) */
  district: Scalars['String'];
  /** An optional nickname for the address. */
  nickname?: InputMaybe<Scalars['String']>;
  /** The nickname for this address. */
  postalCode: Scalars['String'];
  /** The address's state (e.g. `SP`) */
  state: Scalars['String'];
  /** The address's street (e.g. `Rua do Capeta`) */
  street: Scalars['String'];
  /** The address's street number (e.g. `666`) */
  streetNumber: Scalars['String'];
};

/** Address interface. */
export type AddressInterface = {
  /** The address's state (e.g. `Ribeirão Preto`) */
  city: Scalars['String'];
  /** An optional complement (e.g. `Apto 69 (nice)`) */
  complement?: Maybe<Scalars['String']>;
  /** The address's district (e.g. `Jardim Satânico`) */
  district: Scalars['String'];
  /** An optional nickname for the address. */
  nickname?: Maybe<Scalars['String']>;
  /** The nickname for this address. */
  postalCode: Scalars['String'];
  /** The address's state (e.g. `SP`) */
  state: Scalars['String'];
  /** The address's street (e.g. `Rua do Capeta`) */
  street: Scalars['String'];
  /** The address's street number (e.g. `666`) */
  streetNumber: Scalars['String'];
};

/** Address type implementation. */
export type AddressType = AddressInterface & {
  /** The address's state (e.g. `Ribeirão Preto`) */
  city: Scalars['String'];
  /** An optional complement (e.g. `Apto 69 (nice)`) */
  complement?: Maybe<Scalars['String']>;
  /** The address's district (e.g. `Jardim Satânico`) */
  district: Scalars['String'];
  /** An optional nickname for the address. */
  nickname?: Maybe<Scalars['String']>;
  /** The nickname for this address. */
  postalCode: Scalars['String'];
  /** The address's state (e.g. `SP`) */
  state: Scalars['String'];
  /** The address's street (e.g. `Rua do Capeta`) */
  street: Scalars['String'];
  /** The address's street number (e.g. `666`) */
  streetNumber: Scalars['String'];
};

/** An enumeration. */
export enum BannerKind {
  ECommerce = 'E_COMMERCE',
  LandingPage = 'LANDING_PAGE',
  Subscription = 'SUBSCRIPTION',
}

/** Banner item type. */
export type BannerType = Node & {
  /** The banner to desktop. */
  bannerDesktop?: Maybe<ImageType>;
  /** The banner to mobile. */
  bannerMobile?: Maybe<ImageType>;
  ctaAction?: Maybe<Scalars['String']>;
  ctaLabel?: Maybe<Scalars['String']>;
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  /** The ID of the object */
  id: Scalars['ID'];
  kind: BannerKind;
  subtitle1?: Maybe<Scalars['String']>;
  subtitle2?: Maybe<Scalars['String']>;
  title1?: Maybe<Scalars['String']>;
  title2?: Maybe<Scalars['String']>;
};

/** Producer image type. */
export type BoxImageType = Node & {
  /** The producer image. */
  file?: Maybe<ImageType>;
  /** The ID of the object */
  id: Scalars['ID'];
};

/** Box item type. */
export type BoxItemType = Node & {
  /** The ID of the object */
  id: Scalars['ID'];
  /** The image of box item. */
  image?: Maybe<ImageType>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

/** Box product type. */
export type BoxProductType = Node & {
  /** The ID of the object */
  id: Scalars['ID'];
  /** Which product will be added to the order in place of this template */
  product: ProductType;
  /** The template product that will be available for subscriptions */
  template: ProductType;
};

/** Box item type. */
export type BoxType = Node & {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** All images for this box. */
  images: Array<BoxImageType>;
  /** All items in this box */
  items: Array<BoxItemType>;
  /** The box's month. */
  month: Scalars['Date'];
  /** All products in this box */
  products: Array<BoxProductType>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  video?: Maybe<Scalars['String']>;
  /** The thumbnailv for video url to put in an href */
  videoThumbnail?: Maybe<ImageType>;
};

export type BoxTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BoxTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `BoxType` and its cursor. */
export type BoxTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<BoxType>;
};

export type CartCheckoutMutationInput = {
  /** The address for the cart. */
  address?: InputMaybe<Scalars['ID']>;
  /** The cashback code for the order */
  cashbackCode?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "CartType" to mutate */
  id: Scalars['ID'];
  /** Payment installments when the kind is card */
  installments?: InputMaybe<Scalars['Int']>;
  /** The payment method's when kind is card */
  paymentMethod?: InputMaybe<Scalars['ID']>;
  /** The chosen payment method kind. */
  paymentMethodKind: PaymentMethod;
};

/** Finish the given cart. */
export type CartCheckoutMutationPayload = {
  /** The mutated object. */
  cart?: Maybe<CartType>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** Invoice information. Will be null for card checkouts because their processing is delayed. */
  invoiceData?: Maybe<InvoiceChargeInfo>;
  /** The newly created order (for ecommerce or the subscription's first order) */
  order?: Maybe<OrderType>;
  /** The newly created subscription (for subscription finalities) */
  subscription?: Maybe<SubscriptionType>;
};

export type CartCreateOrUpdateMutationInput = {
  /** The items of the generated order is going be add in next subscription delivery. */
  addItemsInNextCycle?: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The cart finality. Will be used together with the user's fingerprint to get/create a cart. */
  finality: FinalityKind;
  /** An optional list of items to add to the cart. Any item in here will be added to the cart or have its quantity increased by the quantity if it is already in the cart. */
  itemsToAdd?: InputMaybe<Array<ProductItemInputType>>;
  /** The subscription plan, required when the finality is SUBSCRIPTION. Will be used together with the finality and user's fingerprint to get/create a cart. */
  plan?: InputMaybe<Scalars['ID']>;
  /** An optional postal code to set or replace the existing on in the cart. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** An optional promocode to set or replace the existing on in the cart. */
  promocode?: InputMaybe<Scalars['String']>;
};

/**
 * Create or update the user's current cart.
 *
 * Important: the items, when provided, will be added to the cart or have their
 * quantity increased by the amount specified.
 */
export type CartCreateOrUpdateMutationPayload = {
  /** The created/updated cart */
  cart: CartType;
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
};

/** Cart interface. */
export type CartInterface = {
  /** If we should add this item in next subscription cycle */
  addItemsInNextCycle?: Maybe<Scalars['Boolean']>;
  /** General Cart Information. */
  cartInfo: InfoType;
  /** Discount specific to Club Veroo. */
  clubDiscount?: Maybe<Scalars['Decimal']>;
  /** Discount for this item. */
  discount: Scalars['Decimal'];
  /** How many cycles the promocode will give discount for */
  discountForCycles?: Maybe<Scalars['Int']>;
  /**
   * Freight for this item.
   * @deprecated We use the freight_data now to offer more descriptive values.
   */
  freight?: Maybe<Scalars['Decimal']>;
  /** Freight for this item. */
  freightData?: Maybe<FreightDataType>;
  /**
   * How many business days to deliver.
   * @deprecated We use the freight_data now to offer more descriptive values.
   */
  freightDays?: Maybe<Scalars['Int']>;
  /** Installments Value when payment method kind is card */
  installments: Array<InstallmentType>;
  /** Items for this cart */
  items: Array<ProductItemType>;
  /** Information regarding the items in the cart. */
  itemsInfo: InfoType;
  /** Number of items in the cart */
  itemsNumber: Scalars['Int'];
  /** If this specific cart is ok (without errors). */
  ok: Scalars['Boolean'];
  /** Information regarding the provided postal code validity. */
  postalCodeInfo: InfoType;
  /** Discount specific to the promocode being used. */
  promocodeDiscount?: Maybe<Scalars['Decimal']>;
  /** Available gifts for this cart to the promocode being used. */
  promocodeGifts?: Maybe<Array<Maybe<PromocodeGiftType>>>;
  /** Information regarding the provided promocode validity. */
  promocodeInfo: InfoType;
  /** Subtotal for this item. */
  subtotal: Scalars['Decimal'];
  /** Information on the minimum price for a subscription. */
  subtotalInfo: InfoType;
  /** Total (subtotal - discount) for this item. */
  total: Scalars['Decimal'];
};

/** Cart type. */
export type CartType = CartInterface &
  Node & {
    /** If we should add this item in next subscription cycle */
    addItemsInNextCycle?: Maybe<Scalars['Boolean']>;
    /** General Cart Information. */
    cartInfo: InfoType;
    /** Discount specific to Club Veroo. */
    clubDiscount?: Maybe<Scalars['Decimal']>;
    /** Discount for this item. */
    discount: Scalars['Decimal'];
    /** How many cycles the promocode will give discount for */
    discountForCycles?: Maybe<Scalars['Int']>;
    /**
     * Freight for this item.
     * @deprecated We use the freight_data now to offer more descriptive values.
     */
    freight?: Maybe<Scalars['Decimal']>;
    /** Freight for this item. */
    freightData?: Maybe<FreightDataType>;
    /**
     * How many business days to deliver.
     * @deprecated We use the freight_data now to offer more descriptive values.
     */
    freightDays?: Maybe<Scalars['Int']>;
    /** The ID of the object */
    id: Scalars['ID'];
    /** Installments Value when payment method kind is card */
    installments: Array<InstallmentType>;
    /** Items for this cart */
    items: Array<ProductItemType>;
    /** Information regarding the items in the cart. */
    itemsInfo: InfoType;
    /** Number of items in the cart */
    itemsNumber: Scalars['Int'];
    /** If this specific cart is ok (without errors). */
    ok: Scalars['Boolean'];
    plan?: Maybe<SubscriptionPlanType>;
    postalCode?: Maybe<Scalars['String']>;
    /** Information regarding the provided postal code validity. */
    postalCodeInfo: InfoType;
    promocodeCode?: Maybe<Scalars['String']>;
    /** Discount specific to the promocode being used. */
    promocodeDiscount?: Maybe<Scalars['Decimal']>;
    /** Available gifts for this cart to the promocode being used. */
    promocodeGifts?: Maybe<Array<Maybe<PromocodeGiftType>>>;
    /** Information regarding the provided promocode validity. */
    promocodeInfo: InfoType;
    /** Subtotal for this item. */
    subtotal: Scalars['Decimal'];
    /** Information on the minimum price for a subscription. */
    subtotalInfo: InfoType;
    /** Total (subtotal - discount) for this item. */
    total: Scalars['Decimal'];
  };

export type CartUpdateMutationInput = {
  /** The items of the generated order is going be add in next subscription delivery. */
  addItemsInNextCycle?: Scalars['Boolean'];
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** If we should fix any issues with items (e.g. missing stocks, etc). */
  fixItems?: InputMaybe<Scalars['Boolean']>;
  /** ID of the "CartType" to mutate */
  id: Scalars['ID'];
  /** An optional list of items to adjust at the cart. Any item passed here will have its value adjusted to the given quantity, and existing items in the cart not present in this list won't have their quantities changed. That means that to remove an item from the cart you have to pass it with a `quantity` set to `0`. */
  items?: InputMaybe<Array<ProductItemInputType>>;
  /** An optional postal code to set or replace the existing on in the cart. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** An optional promocode to set or replace the existing on in the cart. */
  promocode?: InputMaybe<Scalars['String']>;
};

/** Update the given card items and info. */
export type CartUpdateMutationPayload = {
  /** The mutated object. */
  cart?: Maybe<CartType>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
};

export enum CategoryKind {
  Banner = 'BANNER',
  Box = 'BOX',
  Order = 'ORDER',
  Package = 'PACKAGE',
  Product = 'PRODUCT',
  Ticket = 'TICKET',
}

/** An enumeration. */
export enum ChargeKind {
  AddNextCycle = 'ADD_NEXT_CYCLE',
  AdvancePayment = 'ADVANCE_PAYMENT',
  Common = 'COMMON',
  Free = 'FREE',
  PaidInAdvance = 'PAID_IN_ADVANCE',
}

/** Interface for deliverable objects. */
export type DeliverableInterface = {
  /** Freight for this item. */
  freight: Scalars['Decimal'];
  /** How many business days to deliver. */
  freightDays: Scalars['Int'];
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  /** Raise exceptions for this API query. */
  exceptions?: Maybe<Array<Maybe<DjangoDebugException>>>;
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single exception raised. */
export type DjangoDebugException = {
  /** The class of the exception */
  excType: Scalars['String'];
  /** The message of the exception */
  message: Scalars['String'];
  /** The stack trace */
  stack: Scalars['String'];
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
};

export type EventLogCreateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The enumerable kind of the log. */
  kind: EventLogKind;
  /** The object for the event. */
  obj: Scalars['ID'];
};

/** Create an event log. */
export type EventLogCreateMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The newly created event log. */
  eventLog?: Maybe<EventLogType>;
};

/** An enumeration. */
export enum EventLogKind {
  DeliveryMarkDelivered = 'DELIVERY_MARK_DELIVERED',
  DeliveryMarkReceived = 'DELIVERY_MARK_RECEIVED',
  DeliveryPrintReport = 'DELIVERY_PRINT_REPORT',
  IuguHook = 'IUGU_HOOK',
  MandaeHook = 'MANDAE_HOOK',
  PackingFinish = 'PACKING_FINISH',
  PackingPrintInvoice = 'PACKING_PRINT_INVOICE',
  PackingPrintLabel = 'PACKING_PRINT_LABEL',
  PackingStart = 'PACKING_START',
  PagarmeHook = 'PAGARME_HOOK',
}

export type EventLogObjectUnion = OrderType;

/** Event log type. */
export type EventLogType = Node & {
  data: Scalars['JSONString'];
  date: Scalars['DateTime'];
  /** The ID of the object */
  id: Scalars['ID'];
  kind: EventLogKind;
  /** The object itself, which might be one of options in the union. */
  obj?: Maybe<EventLogObjectUnion>;
  objId?: Maybe<Scalars['Int']>;
  user?: Maybe<UserType>;
};

/** Finality kind. */
export enum FinalityKind {
  Ecommerce = 'ECOMMERCE',
  Subscription = 'SUBSCRIPTION',
}

/** Freight information. */
export type FreightDataType = {
  /** How many business days to deliver. */
  days: Scalars['Int'];
  /** Discount for this items. */
  discount: Scalars['Decimal'];
  /** Subtotal for this items. */
  subtotal: Scalars['Decimal'];
  /** Total (subtotal - discount) for this items. */
  total: Scalars['Decimal'];
};

/** An enumeration. */
export enum GatewayChoice {
  Iugu = 'IUGU',
  Pagarme = 'PAGARME',
}

/** Grid option type. */
export type GridOptionType = Node & {
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** All values for this option. */
  values: Array<GridOptionValueType>;
};

export type GridOptionTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GridOptionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `GridOptionType` and its cursor. */
export type GridOptionTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<GridOptionType>;
};

/** Grid option value type. */
export type GridOptionValueType = Node & {
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  option: GridOptionType;
};

export type HealthCheckType = {
  /** Category of the problem */
  category?: Maybe<CategoryKind>;
  /** Link to resolve the problem */
  link?: Maybe<Scalars['String']>;
  /** Description of the problem */
  msg?: Maybe<Scalars['String']>;
};

export enum ImageColorspaceEnum {
  Gray = 'GRAY',
  Rgb = 'RGB',
}

export enum ImageFormatEnum {
  Gif = 'GIF',
  Jpeg = 'JPEG',
  Png = 'PNG',
  Webp = 'WEBP',
}

/** An image url and its alt to put on href. */
export type ImageType = {
  /** The image description to put on alt */
  alt?: Maybe<Scalars['String']>;
  /** The image's filename. */
  name: Scalars['String'];
  /** The image's path to use with `/api/files/image-view` */
  path: Scalars['String'];
  /** The final image url to put in an href */
  url: Scalars['String'];
};

/** An image url and its alt to put on href. */
export type ImageTypeUrlArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  colorspace?: InputMaybe<ImageColorspaceEnum>;
  crop?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<ImageFormatEnum>;
  padding?: InputMaybe<Scalars['Boolean']>;
  paddingColor?: InputMaybe<Scalars['String']>;
  quality?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum InfoKind {
  Error = 'ERROR',
  Ok = 'OK',
}

/** Informational message to the final user. */
export type InfoType = {
  /** The kind of this info message. */
  kind: InfoKind;
  /** The message of the info (null means no message to be displayed). */
  msg?: Maybe<Scalars['String']>;
};

/** Installment information. */
export type InstallmentType = {
  /** Installments possibility. */
  installments: Scalars['Int'];
  /** How much each installment will cost. */
  value: Scalars['Decimal'];
};

/** Invoice charge info. */
export type InvoiceChargeInfo = {
  /** The invoice barcode. */
  bankSlipBarcode?: Maybe<Scalars['String']>;
  /** The invoice pdf. */
  invoicePdf?: Maybe<Scalars['String']>;
  /** The invoice url. */
  invoiceUrl?: Maybe<Scalars['String']>;
  /** The chosen payment method kind. */
  paymentMethodKind?: Maybe<PaymentMethod>;
  /** The invoice pixcode. */
  pixQrcode?: Maybe<Scalars['String']>;
  /** The invoice pix url. */
  pixQrcodeUrl?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum InvoiceStatus {
  Canceled = 'CANCELED',
  Chargeback = 'CHARGEBACK',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  ExternallyPaid = 'EXTERNALLY_PAID',
  InAnalysis = 'IN_ANALYSIS',
  InProtest = 'IN_PROTEST',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
}

/** Subscription item type. */
export type InvoiceType = Node & {
  /** Invoice information. Will be null for card checkouts because their processing is delayed. */
  chargeInfo?: Maybe<InvoiceChargeInfo>;
  dueAt: Scalars['Date'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** If this invoice is late. */
  isLate: Scalars['Boolean'];
  origin: GatewayChoice;
  paidAt?: Maybe<Scalars['DateTime']>;
  status: InvoiceStatus;
  total: Scalars['Decimal'];
  totalPaid: Scalars['Decimal'];
};

export type LeadEventCreateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  finality?: InputMaybe<FinalityKind>;
  items?: InputMaybe<Array<ProductItemInputType>>;
  kind: LeadEventKind;
  /** The promocode for this event. */
  promocode?: InputMaybe<Scalars['String']>;
  subscriptionPlan?: InputMaybe<Scalars['ID']>;
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
};

/**
 * Register a lead event.
 *
 * NOTE: Do not register "checkout-finish" on frontend as it is currently
 * being registered by the backend.
 */
export type LeadEventCreateMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  leadEvent?: Maybe<LeadEventType>;
};

/** An enumeration. */
export enum LeadEventKind {
  CartAbandoned = 'CART_ABANDONED',
  CartInit = 'CART_INIT',
  CartUpdate = 'CART_UPDATE',
  CheckoutAddress = 'CHECKOUT_ADDRESS',
  CheckoutAddPaymentMethod = 'CHECKOUT_ADD_PAYMENT_METHOD',
  CheckoutAddPromocode = 'CHECKOUT_ADD_PROMOCODE',
  CheckoutAddShipping = 'CHECKOUT_ADD_SHIPPING',
  CheckoutDocuments = 'CHECKOUT_DOCUMENTS',
  CheckoutFinish = 'CHECKOUT_FINISH',
  CheckoutInit = 'CHECKOUT_INIT',
  CheckoutPersonalInfo = 'CHECKOUT_PERSONAL_INFO',
  CheckoutPersonKind = 'CHECKOUT_PERSON_KIND',
  CheckoutReview = 'CHECKOUT_REVIEW',
  CheckoutSignin = 'CHECKOUT_SIGNIN',
  FirstOrder = 'FIRST_ORDER',
  Login = 'LOGIN',
  MainCardExpiring = 'MAIN_CARD_EXPIRING',
  MainPageView = 'MAIN_PAGE_VIEW',
  OrderDelivered = 'ORDER_DELIVERED',
  OrderFailed = 'ORDER_FAILED',
  OrderReceived = 'ORDER_RECEIVED',
  ProductView = 'PRODUCT_VIEW',
  SubscriptiomChurnDelinquent = 'SUBSCRIPTIOM_CHURN_DELINQUENT',
  SubscriptionChurn = 'SUBSCRIPTION_CHURN',
  SubscriptionPause = 'SUBSCRIPTION_PAUSE',
  SubscriptionPreChurn = 'SUBSCRIPTION_PRE_CHURN',
  SubscriptionRecovered = 'SUBSCRIPTION_RECOVERED',
  SubscriptionUnpause = 'SUBSCRIPTION_UNPAUSE',
}

/** Lead event type. */
export type LeadEventType = Node & {
  finality?: Maybe<FinalityKind>;
  /** The ID of the object */
  id: Scalars['ID'];
  kind: LeadEventKind;
  order?: Maybe<OrderType>;
  promocode?: Maybe<PromocodeType>;
  subscription?: Maybe<SubscriptionType>;
  user?: Maybe<UserType>;
};

export type Mutation = {
  /** Finish the given cart. */
  cartCheckout?: Maybe<CartCheckoutMutationPayload>;
  /**
   * Create or update the user's current cart.
   *
   * Important: the items, when provided, will be added to the cart or have their
   * quantity increased by the amount specified.
   */
  cartCreateOrUpdate?: Maybe<CartCreateOrUpdateMutationPayload>;
  /** Update the given card items and info. */
  cartUpdate?: Maybe<CartUpdateMutationPayload>;
  /** Create an event log. */
  eventLogCreate?: Maybe<EventLogCreateMutationPayload>;
  /**
   * Register a lead event.
   *
   * NOTE: Do not register "checkout-finish" on frontend as it is currently
   * being registered by the backend.
   */
  leadEventCreate?: Maybe<LeadEventCreateMutationPayload>;
  node?: Maybe<Node>;
  /** Charges an order using a payment method. */
  orderCharge?: Maybe<OrderChargeMutationPayload>;
  /** Mark an order as packed. */
  orderPackFinish?: Maybe<OrderPackFinishMutationPayload>;
  /** Open a ticket for the order. */
  orderPackOpenTicket?: Maybe<OrderPackOpenTicketMutationPayload>;
  /** Start the packing of an order. */
  orderPackStart?: Maybe<OrderPackStartMutationPayload>;
  /** Mark an order as received. */
  orderReceive?: Maybe<OrderReceiveMutationPayload>;
  /** Mark the orders as delivered. */
  ordersDelivery?: Maybe<OrdersDeliveryMutationPayload>;
  /** Create a new recovery subscription. */
  ordersPrintDelivery?: Maybe<OrdersPrintDeliveryMutationPayload>;
  /** Mark the orders as received. */
  ordersReceive?: Maybe<OrdersReceiveMutationPayload>;
  /** Create a new payment method for a user. */
  paymentMethodCreate?: Maybe<PaymentMethodCreateMutationPayload>;
  /** Delete a payment method from a user. */
  paymentMethodDelete?: Maybe<PaymentMethodDeleteMutationPayload>;
  /** Set a payment method as the default from a user. */
  paymentMethodUpdate?: Maybe<PaymentMethodUpdateMutationPayload>;
  /** Toggles the pause status of a subscription. */
  subscriptionCancel?: Maybe<SubscriptionCancelMutationPayload>;
  /** Change the subscription items. */
  subscriptionChange?: Maybe<SubscriptionChangeMutationPayload>;
  /** Change the subscription next cycle. */
  subscriptionChangeNextCycle?: Maybe<SubscriptionChangeNextCyclePayload>;
  /** Toggles the pause status of a subscription. */
  subscriptionPauseToggle?: Maybe<SubscriptionPauseToggleMutationPayload>;
  /** Change the subscription next cycle. */
  subscriptionUndoCancel?: Maybe<SubscriptionUndoCancelMutationPayload>;
  /** Create a new ticket. */
  ticketCreate?: Maybe<TicketCreateMutationPayload>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebTokenPayload>;
  tokenRefresh?: Maybe<RefreshPayload>;
  tokenRevoke?: Maybe<RevokePayload>;
  tokenVerify?: Maybe<VerifyPayload>;
  /** Create a new address for a user. */
  userAddressCreate?: Maybe<UserAddressCreateMutationPayload>;
  /** Create a new address for a user. */
  userAddressDelete?: Maybe<UserAddressDeleteMutationPayload>;
  /** Simulate the new prices when changing the shipping address. */
  userAddressShippingSimulation?: Maybe<UserAddressShippingSimulationMutationPayload>;
  /** Create a new address for a user. */
  userAddressUpdate?: Maybe<UserAddressUpdateMutationPayload>;
  userLoginByEmail?: Maybe<UserLoginByEmailPayload>;
  userLoginValidate?: Maybe<UserLoginValidatePayload>;
  /** Update a user's information. */
  userUpdate?: Maybe<UserUpdateMutationPayload>;
};

export type MutationCartCheckoutArgs = {
  input: CartCheckoutMutationInput;
};

export type MutationCartCreateOrUpdateArgs = {
  input: CartCreateOrUpdateMutationInput;
};

export type MutationCartUpdateArgs = {
  input: CartUpdateMutationInput;
};

export type MutationEventLogCreateArgs = {
  input: EventLogCreateMutationInput;
};

export type MutationLeadEventCreateArgs = {
  input: LeadEventCreateMutationInput;
};

export type MutationNodeArgs = {
  id: Scalars['ID'];
};

export type MutationOrderChargeArgs = {
  input: OrderChargeMutationInput;
};

export type MutationOrderPackFinishArgs = {
  input: OrderPackFinishMutationInput;
};

export type MutationOrderPackOpenTicketArgs = {
  input: OrderPackOpenTicketMutationInput;
};

export type MutationOrderPackStartArgs = {
  input: OrderPackStartMutationInput;
};

export type MutationOrderReceiveArgs = {
  input: OrderReceiveMutationInput;
};

export type MutationOrdersDeliveryArgs = {
  input: OrdersDeliveryMutationInput;
};

export type MutationOrdersPrintDeliveryArgs = {
  input: OrdersPrintDeliveryMutationInput;
};

export type MutationOrdersReceiveArgs = {
  input: OrdersReceiveMutationInput;
};

export type MutationPaymentMethodCreateArgs = {
  input: PaymentMethodCreateMutationInput;
};

export type MutationPaymentMethodDeleteArgs = {
  input: PaymentMethodDeleteMutationInput;
};

export type MutationPaymentMethodUpdateArgs = {
  input: PaymentMethodUpdateMutationInput;
};

export type MutationSubscriptionCancelArgs = {
  input: SubscriptionCancelMutationInput;
};

export type MutationSubscriptionChangeArgs = {
  input: SubscriptionChangeMutationInput;
};

export type MutationSubscriptionChangeNextCycleArgs = {
  input: SubscriptionChangeNextCycleInput;
};

export type MutationSubscriptionPauseToggleArgs = {
  input: SubscriptionPauseToggleMutationInput;
};

export type MutationSubscriptionUndoCancelArgs = {
  input: SubscriptionUndoCancelMutationInput;
};

export type MutationTicketCreateArgs = {
  input: TicketCreateMutationInput;
};

export type MutationTokenAuthArgs = {
  input: ObtainJsonWebTokenInput;
};

export type MutationTokenRefreshArgs = {
  input: RefreshInput;
};

export type MutationTokenRevokeArgs = {
  input: RevokeInput;
};

export type MutationTokenVerifyArgs = {
  input: VerifyInput;
};

export type MutationUserAddressCreateArgs = {
  input: UserAddressCreateMutationInput;
};

export type MutationUserAddressDeleteArgs = {
  input: UserAddressDeleteMutationInput;
};

export type MutationUserAddressShippingSimulationArgs = {
  input: UserAddressShippingSimulationMutationInput;
};

export type MutationUserAddressUpdateArgs = {
  input: UserAddressUpdateMutationInput;
};

export type MutationUserLoginByEmailArgs = {
  input: UserLoginByEmailInput;
};

export type MutationUserLoginValidateArgs = {
  input: UserLoginValidateInput;
};

export type MutationUserUpdateArgs = {
  input: UserUpdateMutationInput;
};

/** An error that happened in a mutation. */
export type MutationErrorType = {
  /** The field that caused the error, or `null` if it isn't associated with any particular field. */
  field?: Maybe<Scalars['String']>;
  /** The error message. */
  message?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

export type ObtainJsonWebTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebTokenPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type OrderChargeMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "OrderType" to mutate */
  id: Scalars['ID'];
  /** The payment method's when kind is card */
  paymentMethod?: InputMaybe<Scalars['ID']>;
  /** The chosen payment method kind. */
  paymentMethodKind: PaymentMethod;
};

/** Charges an order using a payment method. */
export type OrderChargeMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  order?: Maybe<OrderType>;
};

/** Order item type. */
export type OrderItemType = Node &
  TotalizableInterface & {
    createdAt: Scalars['DateTime'];
    /** Discount for this item. */
    discount: Scalars['Decimal'];
    /**
     * The grid option value for this product.
     * @deprecated Get from productGrid instead
     */
    gridOptionValue?: Maybe<GridOptionValueType>;
    /** The ID of the object */
    id: Scalars['ID'];
    /** The unit price for this item */
    price: Scalars['Decimal'];
    product: ProductType;
    /** The grid for this item. */
    productGrid?: Maybe<ProductGridType>;
    /** The quantity for this item */
    quantity: Scalars['Int'];
    /** Subtotal for this item. */
    subtotal: Scalars['Decimal'];
    /** Total (subtotal - discount) for this item. */
    total: Scalars['Decimal'];
    updatedAt: Scalars['DateTime'];
  };

/** An enumeration. */
export enum OrderKind {
  Sale = 'SALE',
  Shipment = 'SHIPMENT',
}

export type OrderPackFinishMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "OrderType" to mutate */
  id: Scalars['ID'];
};

/** Mark an order as packed. */
export type OrderPackFinishMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  order?: Maybe<OrderType>;
};

export type OrderPackOpenTicketMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A description of the issue. */
  description: Scalars['String'];
  /** ID of the "OrderType" to mutate */
  id: Scalars['ID'];
};

/** Open a ticket for the order. */
export type OrderPackOpenTicketMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  order?: Maybe<OrderType>;
  /** The newly created ticket. */
  ticket: TicketType;
};

export type OrderPackStartMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "OrderType" to mutate */
  id: Scalars['ID'];
};

/** Start the packing of an order. */
export type OrderPackStartMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  order?: Maybe<OrderType>;
};

export type OrderReceiveMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "OrderType" to mutate */
  id: Scalars['ID'];
};

/** Mark an order as received. */
export type OrderReceiveMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  order?: Maybe<OrderType>;
};

/** An enumeration. */
export enum OrderStatus {
  Canceled = 'CANCELED',
  Delivered = 'DELIVERED',
  Finished = 'FINISHED',
  Initial = 'INITIAL',
  Packing = 'PACKING',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Received = 'RECEIVED',
  Waiting = 'WAITING',
}

/** Order type. */
export type OrderType = AddressInterface &
  DeliverableInterface &
  Node &
  TotalizableInterface & {
    canceledAt?: Maybe<Scalars['DateTime']>;
    chargeInstallments: Scalars['Int'];
    chargeKind?: Maybe<ChargeKind>;
    /** The address's state (e.g. `Ribeirão Preto`) */
    city: Scalars['String'];
    /** An optional complement (e.g. `Apto 69 (nice)`) */
    complement?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    deliveredAt?: Maybe<Scalars['DateTime']>;
    /** Discount for this item. */
    discount: Scalars['Decimal'];
    /** The address's district (e.g. `Jardim Satânico`) */
    district: Scalars['String'];
    expiresAt?: Maybe<Scalars['DateTime']>;
    /** Freight for this item. */
    freight: Scalars['Decimal'];
    /** Freight for this item. */
    freightData: FreightDataType;
    /** How many business days to deliver. */
    freightDays: Scalars['Int'];
    freightKind?: Maybe<ShippingKind>;
    /** The ID of the object */
    id: Scalars['ID'];
    /** The invoice for this order. */
    invoice?: Maybe<InvoiceType>;
    /** Items for this order */
    items: Array<OrderItemType>;
    /** The kind of the order */
    kind?: Maybe<OrderKind>;
    /** An optional nickname for the address. */
    nickname?: Maybe<Scalars['String']>;
    observations?: Maybe<Scalars['String']>;
    /** Packing for this order */
    packing: Array<PackingType>;
    packingAt?: Maybe<Scalars['DateTime']>;
    parent?: Maybe<OrderType>;
    pendingAt?: Maybe<Scalars['DateTime']>;
    /** The nickname for this address. */
    postalCode: Scalars['String'];
    processingAt?: Maybe<Scalars['DateTime']>;
    receivedAt?: Maybe<Scalars['DateTime']>;
    /** The address's state (e.g. `SP`) */
    state: Scalars['String'];
    status?: Maybe<OrderStatus>;
    /** The address's street (e.g. `Rua do Capeta`) */
    street: Scalars['String'];
    /** The address's street number (e.g. `666`) */
    streetNumber: Scalars['String'];
    subscription?: Maybe<SubscriptionType>;
    /** Subtotal for this item. */
    subtotal: Scalars['Decimal'];
    /** Total (subtotal - discount) for this item. */
    total: Scalars['Decimal'];
    /** The tracking code for this order. */
    trackingCode: Scalars['String'];
    /** The tracking code url this order. */
    trackingCodeUrl?: Maybe<Scalars['String']>;
    updatedAt: Scalars['DateTime'];
    user: UserType;
    waitingAt?: Maybe<Scalars['DateTime']>;
  };

export type OrderTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OrderTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `OrderType` and its cursor. */
export type OrderTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<OrderType>;
};

export type OrdersDeliveryMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A list of ids to mark as delivered. */
  ids: Array<Scalars['ID']>;
};

/** Mark the orders as delivered. */
export type OrdersDeliveryMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The changed orders. */
  orders?: Maybe<Array<OrderType>>;
};

export type OrdersPrintDeliveryMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A list of ids to mark as delivered. */
  ids: Array<Scalars['ID']>;
};

/** Create a new recovery subscription. */
export type OrdersPrintDeliveryMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The changed orders. */
  orders?: Maybe<Array<OrderType>>;
  /** The id to ask to print the orders. This will last for 15 minutes */
  printId?: Maybe<Scalars['UUID']>;
};

export type OrdersReceiveMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A list of ids to mark as received. */
  ids: Array<Scalars['ID']>;
};

/** Mark the orders as received. */
export type OrdersReceiveMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The changed orders. */
  orders?: Maybe<Array<OrderType>>;
};

/** Packing product type. */
export type PackingProductType = {
  /** The product name. */
  name: Scalars['String'];
  /** An observation regarding the product. */
  observation?: Maybe<Scalars['String']>;
  /** The quantity for the product. */
  quantity: Scalars['Int'];
};

/** Packing type. */
export type PackingType = {
  /** The id of the box. */
  boxId?: Maybe<Scalars['Int']>;
  /** The name of the box. */
  boxName: Scalars['String'];
  /** The products that should be packaged in this box. */
  products: Array<PackingProductType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Payment method. */
export enum PaymentMethod {
  BankSlip = 'BANK_SLIP',
  Card = 'CARD',
  Pix = 'PIX',
}

export type PaymentMethodCreateMutationInput = {
  /** The address to use for this card. Will use the user's billing if not provided */
  address?: InputMaybe<AddressInput>;
  /** The cart id to associate with a lead event. Should be sent only when this is being executed in a checkout process */
  cart?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Optional label to identify the card */
  label?: InputMaybe<Scalars['String']>;
  /** The payment token when payment method is card */
  paymentToken: Scalars['String'];
  /** If we should set this method to be the default one */
  setDefault?: InputMaybe<Scalars['Boolean']>;
  /** reCAPTCHA token */
  token: Scalars['String'];
};

/** Create a new payment method for a user. */
export type PaymentMethodCreateMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The newly created payment method */
  paymentMethod?: Maybe<UserPaymentMethodType>;
};

export type PaymentMethodDeleteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The payment method's id */
  id: Scalars['ID'];
};

/** Delete a payment method from a user. */
export type PaymentMethodDeleteMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The just removed payment method */
  paymentMethod?: Maybe<UserPaymentMethodType>;
};

export type PaymentMethodUpdateMutationInput = {
  /** The address to use for this card. */
  address?: InputMaybe<AddressInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The payment method's id */
  id: Scalars['ID'];
  /** If we should set this method to be the default one */
  setDefault?: InputMaybe<Scalars['Boolean']>;
  /** reCAPTCHA token */
  token: Scalars['String'];
};

/** Set a payment method as the default from a user. */
export type PaymentMethodUpdateMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The altered payment method */
  paymentMethod?: Maybe<UserPaymentMethodType>;
};

/** Person kind. */
export enum PersonKind {
  Legal = 'LEGAL',
  Natural = 'NATURAL',
}

/** Popup type. */
export type PopUpType = Node & {
  body?: Maybe<Scalars['String']>;
  ctaAction?: Maybe<Scalars['String']>;
  ctaLabel?: Maybe<Scalars['String']>;
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** The image of popup. */
  image?: Maybe<ImageType>;
  section?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Producer image type. */
export type ProducerImageType = Node & {
  /** The producer image. */
  file?: Maybe<ImageType>;
  /** The ID of the object */
  id: Scalars['ID'];
};

/** Producer type. */
export type ProducerType = Node & {
  /** The image avatar of producer. */
  avatar?: Maybe<ImageType>;
  /** The description already converted to html. */
  descriptionHtml: Scalars['String'];
  farm: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** All images for this product. */
  images: Array<ProducerImageType>;
  metaDescription: Scalars['String'];
  name: Scalars['String'];
  products: ProductTypeConnection;
  region: RegionType;
  slug: Scalars['String'];
  video?: Maybe<Scalars['String']>;
  /** The thumbnailv for video url to put in an href */
  videoThumbnail?: Maybe<ImageType>;
};

/** Producer type. */
export type ProducerTypeProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isOnSale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  producer?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  variety?: InputMaybe<Scalars['ID']>;
};

export type ProducerTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProducerTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ProducerType` and its cursor. */
export type ProducerTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProducerType>;
};

/** An enumeration. */
export enum ProductCoffeeKind {
  Beans = 'BEANS',
  Capsule = 'CAPSULE',
  Ground = 'GROUND',
}

/** Product feedback type. */
export type ProductFeedbackType = Node & {
  /** The image avatar of user owner of feedback. */
  avatar?: Maybe<ImageType>;
  comment: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
};

/** Product grid type. */
export type ProductGridType = Node & {
  /** The ID of the object */
  id: Scalars['ID'];
  /** This grid option. */
  image?: Maybe<ImageType>;
  /** Stock quantity for this grid variant */
  stock: Scalars['Int'];
  value: GridOptionValueType;
};

/** Product image type. */
export type ProductImageType = Node & {
  /** The image of product. */
  file?: Maybe<ImageType>;
  /** The ID of the object */
  id: Scalars['ID'];
};

/** Product item input type. */
export type ProductItemInputType = {
  /** The grid option value for this product. */
  gridOptionValue?: InputMaybe<Scalars['ID']>;
  /** Price for a single product (used for some mutations). */
  price?: InputMaybe<Scalars['Decimal']>;
  /** The product itself. */
  product: Scalars['ID'];
  /** The product grid for this item. */
  productGrid?: InputMaybe<Scalars['ID']>;
  /** How many items of the product. */
  quantity: Scalars['Int'];
};

/** Product item type. */
export type ProductItemType = TotalizableInterface & {
  /** Discount for this item. */
  discount: Scalars['Decimal'];
  /**
   * The grid option value for this product.
   * @deprecated Get from productGrid instead
   */
  gridOptionValue?: Maybe<GridOptionValueType>;
  /** Price for a single product. */
  price: Scalars['Decimal'];
  /** The product itself. */
  product: ProductType;
  /** The product grid for this item. */
  productGrid?: Maybe<ProductGridType>;
  /** How many items of the product. */
  quantity: Scalars['Int'];
  /** Subtotal for this item. */
  subtotal: Scalars['Decimal'];
  /** Total (subtotal - discount) for this item. */
  total: Scalars['Decimal'];
};

/** An enumeration. */
export enum ProductKind {
  Accessory = 'ACCESSORY',
  Coffee = 'COFFEE',
  Kit = 'KIT',
  Template = 'TEMPLATE',
  Utensil = 'UTENSIL',
}

/** Subscription change simulation. */
export type ProductShippingSimulationType = {
  /**
   * Freight for this item.
   * @deprecated We use the freight_data now to offer more descriptive values.
   */
  freight?: Maybe<Scalars['Decimal']>;
  /** Freight for this item. */
  freightData?: Maybe<FreightDataType>;
  /**
   * How many business days to deliver.
   * @deprecated We use the freight_data now to offer more descriptive values.
   */
  freightDays?: Maybe<Scalars['Int']>;
  /** Information regarding the provided postal code validity. */
  info: InfoType;
};

/** Product type. */
export type ProductType = Node & {
  altitude?: Maybe<Scalars['Int']>;
  characteristics?: Maybe<Scalars['String']>;
  /** The discount for subscribers in percentage */
  clubDiscount: Scalars['Decimal'];
  /** The product price for subscribers. */
  clubPrice: Scalars['Decimal'];
  coffeeKind?: Maybe<ProductCoffeeKind>;
  createdAt: Scalars['DateTime'];
  /** Depth in cm */
  depth?: Maybe<Scalars['Decimal']>;
  /** The description already converted to html. */
  descriptionHtml: Scalars['String'];
  /** All feedbacks for this product */
  feedbacks: Array<ProductFeedbackType>;
  foodPairing?: Maybe<Scalars['String']>;
  /** All grid variants for this product. */
  grids: Array<ProductGridType>;
  /** Height in cm */
  height?: Maybe<Scalars['Decimal']>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** All images for this product. */
  images: Array<ProductImageType>;
  isFeatured: Scalars['Boolean'];
  isFresh: Scalars['Boolean'];
  /** If this product is on sale. */
  isOnSale: Scalars['Boolean'];
  /** Product sold out */
  isSoldOut?: Maybe<Scalars['Boolean']>;
  kind: ProductKind;
  metaDescription: Scalars['String'];
  metaTitle: Scalars['String'];
  name: Scalars['String'];
  olfactory?: Maybe<Scalars['String']>;
  /** The on sale discount. */
  onSaleDiscount?: Maybe<Scalars['Decimal']>;
  originalPrice?: Maybe<Scalars['Decimal']>;
  /** The product's price */
  price: Scalars['Decimal'];
  producer?: Maybe<ProducerType>;
  /** The quantity based on the unit (e.g. 250 for 250g, 1000 for 1kg, 10 for 10un, etc) */
  quantity: Scalars['Decimal'];
  /** The quantity formatted with product's unit. */
  quantityStr?: Maybe<Scalars['String']>;
  region?: Maybe<RegionType>;
  /** SCA report of Product */
  scaReportUrl?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  /** Stock quantity for this product */
  stock: Scalars['Int'];
  taste?: Maybe<Scalars['String']>;
  unit: ProductUnit;
  updatedAt: Scalars['DateTime'];
  variety?: Maybe<VarietyType>;
  visual?: Maybe<Scalars['String']>;
  /** Weight in g */
  weight?: Maybe<Scalars['Decimal']>;
  /** Width in cm */
  width?: Maybe<Scalars['Decimal']>;
};

/** Product type. */
export type ProductTypePriceArgs = {
  quantity?: InputMaybe<Scalars['Int']>;
};

export type ProductTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ProductType` and its cursor. */
export type ProductTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<ProductType>;
};

/** An enumeration. */
export enum ProductUnit {
  G = 'G',
  Un = 'UN',
}

/** Promocode gift type. */
export type PromocodeDiscountType = Node & {
  discountForCycles: Scalars['Int'];
  /** Do not allow to use this if the subtotal is below this value */
  discountMinimumValue?: Maybe<Scalars['Decimal']>;
  discountPercent?: Maybe<Scalars['Decimal']>;
  discountValue?: Maybe<Scalars['Decimal']>;
  firstOrderOnly: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
  kind: PromocodeKind;
  /** The grid for this item. */
  productGrid?: Maybe<ProductGridType>;
  /** The kind of the subscription */
  subKind?: Maybe<SubscriptionKind>;
  /** Allowed usage for this discount. */
  usage: FinalityKind;
};

/** Promocode gift type. */
export type PromocodeGiftType = Node & {
  /** The grid option chosen for this item */
  gridOptionValue?: Maybe<GridOptionValueType>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** Gift that the buyer will receive upon using this */
  product: ProductType;
  /** The grid for this item. */
  productGrid?: Maybe<ProductGridType>;
  /** The quantity for this item */
  quantity: Scalars['Int'];
  /** The kind of the subscription */
  subKind?: Maybe<SubscriptionKind>;
};

/** An enumeration. */
export enum PromocodeKind {
  Flat = 'FLAT',
  Percent = 'PERCENT',
}

/** Promocode mgm gift type. */
export type PromocodeMgmGiftType = Node & {
  /** The grid option chosen for this item */
  gridOptionValue?: Maybe<GridOptionValueType>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** Gift that the member that indicated the person will receive */
  product: ProductType;
  /** The grid for this item. */
  productGrid?: Maybe<ProductGridType>;
  /** The quantity for this item */
  quantity: Scalars['Int'];
  /** The kind of the subscription */
  subKind?: Maybe<SubscriptionKind>;
};

/** Promocode type. */
export type PromocodeType = Node & {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  discounts: Array<PromocodeDiscountType>;
  expiresAt: Scalars['DateTime'];
  gifts: Array<PromocodeGiftType>;
  /** The ID of the object */
  id: Scalars['ID'];
  isMgm: Scalars['Boolean'];
  mgmGifts: Array<PromocodeMgmGiftType>;
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type Query = {
  _debug?: Maybe<DjangoDebug>;
  /** All banners for the day. */
  banners: Array<BannerType>;
  /** All boxes in the database. */
  boxes?: Maybe<BoxTypeConnection>;
  /** Current box for the day. */
  currentBox?: Maybe<BoxType>;
  /** Current Promocode mgm. */
  currentMgmPromocode?: Maybe<PromocodeType>;
  /** Current popup for the day. */
  currentPopUp?: Maybe<PopUpType>;
  /** All available grid options. */
  gridOptions?: Maybe<GridOptionTypeConnection>;
  /** All problems of system. */
  healthCheck?: Maybe<Array<Maybe<HealthCheckType>>>;
  /** The currently logged user. */
  me?: Maybe<UserType>;
  /** All addresses for the current user. */
  myAddresses?: Maybe<Array<UserAddressType>>;
  /** User's current cart given/based on the finality and plan. */
  myCart?: Maybe<CartType>;
  /** All orders for the current user. */
  myOrders?: Maybe<OrderTypeConnection>;
  /** All payment methods for the current user. */
  myPaymentMethods?: Maybe<Array<UserPaymentMethodType>>;
  /** An order in the database. */
  order?: Maybe<OrderType>;
  /** All orders for delivery */
  ordersForDelivery: Array<OrderType>;
  /** All orders for packing. */
  ordersForPacking?: Maybe<OrderTypeConnection>;
  /** All orders that can be received */
  ordersForReceival: OrderTypeConnection;
  /** A single plan given its id. */
  plan?: Maybe<SubscriptionPlanType>;
  /** All active plans in the database */
  plans: Array<SubscriptionPlanType>;
  /** A single producer given its id. */
  producer?: Maybe<ProducerType>;
  /** All producers */
  producers?: Maybe<ProducerTypeConnection>;
  /** A single product given its id. */
  product?: Maybe<ProductType>;
  /** Shipping simulation for the given product. */
  productShippingSimulation?: Maybe<ProductShippingSimulationType>;
  /** All available products. */
  products?: Maybe<ProductTypeConnection>;
  /** The promocode given its code. */
  promocode?: Maybe<PromocodeType>;
  subscriptionChangeSimulation?: Maybe<SubscriptionChangeSimulationType>;
  /** All available testimonials. */
  testimonials?: Maybe<Array<TestimonialType>>;
};

export type QueryBannersArgs = {
  kind?: InputMaybe<BannerKind>;
};

export type QueryBoxesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGridOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_Iexact?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryMyCartArgs = {
  createIfNotExists?: InputMaybe<Scalars['Boolean']>;
  finality: FinalityKind;
  plan?: InputMaybe<Scalars['ID']>;
};

export type QueryMyOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatus>;
  status_In?: InputMaybe<Array<InputMaybe<OrderStatus>>>;
  subscription?: InputMaybe<Scalars['ID']>;
  subscription_Isnull?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrdersForDeliveryArgs = {
  freightKind: ShippingKind;
};

export type QueryOrdersForPackingArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatus>;
  status_In?: InputMaybe<Array<InputMaybe<OrderStatus>>>;
  subscription?: InputMaybe<Scalars['ID']>;
  subscription_Isnull?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrdersForReceivalArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrderStatus>;
  status_In?: InputMaybe<Array<InputMaybe<OrderStatus>>>;
  subscription?: InputMaybe<Scalars['ID']>;
  subscription_Isnull?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPlanArgs = {
  id: Scalars['ID'];
};

export type QueryPlansArgs = {
  coffeeKind?: InputMaybe<ProductCoffeeKind>;
};

export type QueryProducerArgs = {
  id: Scalars['ID'];
};

export type QueryProducersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductShippingSimulationArgs = {
  addItemsInNextCycle?: Scalars['Boolean'];
  finality: FinalityKind;
  postalCode: Scalars['String'];
  products: Array<ProductItemInputType>;
};

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  coffeeKinds?: InputMaybe<Array<InputMaybe<ProductCoffeeKind>>>;
  finality: FinalityKind;
  first?: InputMaybe<Scalars['Int']>;
  gridOptionValue?: InputMaybe<Scalars['ID']>;
  isOnSale?: InputMaybe<Scalars['Boolean']>;
  kinds?: InputMaybe<Array<InputMaybe<ProductKind>>>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderby?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  producer?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  variety?: InputMaybe<Scalars['ID']>;
};

export type QueryPromocodeArgs = {
  code: Scalars['String'];
  plan?: InputMaybe<Scalars['ID']>;
};

export type QuerySubscriptionChangeSimulationArgs = {
  items?: InputMaybe<Array<ProductItemInputType>>;
  subscription: Scalars['ID'];
};

export type RefreshInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type RefreshPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

/** Region type. */
export type RegionType = Node & {
  /** The ID of the object */
  id: Scalars['ID'];
  /** The region image. */
  image?: Maybe<ImageType>;
  name: Scalars['String'];
  slug: Scalars['String'];
  state: Scalars['String'];
  stateCode: Scalars['String'];
};

export type RevokeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type RevokePayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  revoked: Scalars['Int'];
};

/** An enumeration. */
export enum ShippingKind {
  Local = 'LOCAL',
  Mandae = 'MANDAE',
  TotalExpress = 'TOTAL_EXPRESS',
}

export type SubscriptionCancelMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "SubscriptionType" to mutate */
  id: Scalars['ID'];
  /** The enumerable reason. */
  reason: SubscriptionChangeReason;
  /** The reason for the cancellation as a text. */
  reasonText?: InputMaybe<Scalars['String']>;
};

/** Toggles the pause status of a subscription. */
export type SubscriptionCancelMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  subscription?: Maybe<SubscriptionType>;
};

export type SubscriptionChangeMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "SubscriptionType" to mutate */
  id: Scalars['ID'];
  items: Array<ProductItemInputType>;
};

/** Change the subscription items. */
export type SubscriptionChangeMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  subscription?: Maybe<SubscriptionType>;
};

export type SubscriptionChangeNextCycleInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  day: Scalars['Int'];
  /** ID of the "SubscriptionType" to mutate */
  id: Scalars['ID'];
};

/** Change the subscription next cycle. */
export type SubscriptionChangeNextCyclePayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  subscription?: Maybe<SubscriptionType>;
};

/** An enumeration. */
export enum SubscriptionChangeReason {
  AskedToCancel = 'ASKED_TO_CANCEL',
  CoffeePassCanceled = 'COFFEE_PASS_CANCELED',
  CoffeePassRemoved = 'COFFEE_PASS_REMOVED',
  DidNotLike = 'DID_NOT_LIKE',
  HavingProblems = 'HAVING_PROBLEMS',
  NonPayment = 'NON_PAYMENT',
  Other = 'OTHER',
  SinglePurchase = 'SINGLE_PURCHASE',
  TooExpensive = 'TOO_EXPENSIVE',
  TooMuchCoffee = 'TOO_MUCH_COFFEE',
}

/** Subscription change simulation. */
export type SubscriptionChangeSimulationType = CartInterface & {
  /** If we should add this item in next subscription cycle */
  addItemsInNextCycle?: Maybe<Scalars['Boolean']>;
  /** General Cart Information. */
  cartInfo: InfoType;
  /** Discount specific to Club Veroo. */
  clubDiscount?: Maybe<Scalars['Decimal']>;
  /** Discount for this item. */
  discount: Scalars['Decimal'];
  /** How many cycles the promocode will give discount for */
  discountForCycles?: Maybe<Scalars['Int']>;
  /**
   * Freight for this item.
   * @deprecated We use the freight_data now to offer more descriptive values.
   */
  freight?: Maybe<Scalars['Decimal']>;
  /** Freight for this item. */
  freightData?: Maybe<FreightDataType>;
  /**
   * How many business days to deliver.
   * @deprecated We use the freight_data now to offer more descriptive values.
   */
  freightDays?: Maybe<Scalars['Int']>;
  /** Installments Value when payment method kind is card */
  installments: Array<InstallmentType>;
  /** Items for this cart */
  items: Array<ProductItemType>;
  /** Information regarding the items in the cart. */
  itemsInfo: InfoType;
  /** Number of items in the cart */
  itemsNumber: Scalars['Int'];
  /** If this specific cart is ok (without errors). */
  ok: Scalars['Boolean'];
  /** Information regarding the provided postal code validity. */
  postalCodeInfo: InfoType;
  /** Discount specific to the promocode being used. */
  promocodeDiscount?: Maybe<Scalars['Decimal']>;
  /** Available gifts for this cart to the promocode being used. */
  promocodeGifts?: Maybe<Array<Maybe<PromocodeGiftType>>>;
  /** Information regarding the provided promocode validity. */
  promocodeInfo: InfoType;
  /** Subtotal for this item. */
  subtotal: Scalars['Decimal'];
  /** Information on the minimum price for a subscription. */
  subtotalInfo: InfoType;
  /** Total (subtotal - discount) for this item. */
  total: Scalars['Decimal'];
};

/** Subscription item type. */
export type SubscriptionItemType = Node &
  TotalizableInterface & {
    createdAt: Scalars['DateTime'];
    /** Discount for this item. */
    discount: Scalars['Decimal'];
    /**
     * The grid option value for this product.
     * @deprecated Get from productGrid instead
     */
    gridOptionValue?: Maybe<GridOptionValueType>;
    /** The ID of the object */
    id: Scalars['ID'];
    /** The unit price for this item */
    price: Scalars['Decimal'];
    product: ProductType;
    /** The grid for this item. */
    productGrid?: Maybe<ProductGridType>;
    /** The quantity for this item */
    quantity: Scalars['Int'];
    /** Subtotal for this item. */
    subtotal: Scalars['Decimal'];
    /** Total (subtotal - discount) for this item. */
    total: Scalars['Decimal'];
    updatedAt: Scalars['DateTime'];
  };

/** An enumeration. */
export enum SubscriptionKind {
  Biannual = 'BIANNUAL',
  CoffeePass = 'COFFEE_PASS',
  Free = 'FREE',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export type SubscriptionPauseToggleMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Pause for this much cycles */
  forCycles?: InputMaybe<Scalars['Int']>;
  /** ID of the "SubscriptionType" to mutate */
  id: Scalars['ID'];
  /** The enumerable reason. */
  reason?: InputMaybe<SubscriptionChangeReason>;
  /** The reason for the pause as a text. */
  reasonText?: InputMaybe<Scalars['String']>;
};

/** Toggles the pause status of a subscription. */
export type SubscriptionPauseToggleMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  subscription?: Maybe<SubscriptionType>;
};

/** Subscription plan product type. */
export type SubscriptionPlanProductType = Node & {
  /**
   * The grid option value for this product.
   * @deprecated Get from productGrid instead
   */
  gridOptionValue?: Maybe<GridOptionValueType>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** The minimum quantity to subscribe to this item */
  minimumQuantity: Scalars['Int'];
  product: ProductType;
  /** The grid for this item. */
  productGrid?: Maybe<ProductGridType>;
};

/** Subscription plan type. */
export type SubscriptionPlanType = Node & {
  /** The coffee kind of this plan. Empty means custom (there can be only one) */
  coffeeKind?: Maybe<ProductCoffeeKind>;
  cta?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  /** The discount that will be applied to all products when signing up to this plan. */
  discountPercent?: Maybe<Scalars['Decimal']>;
  gift?: Maybe<ProductType>;
  /** The name of the gift that will be displayed in the subscription process */
  giftName?: Maybe<Scalars['String']>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** The region image. */
  image?: Maybe<ImageType>;
  isPublic: Scalars['Boolean'];
  /** The kind of the plan */
  kind: SubscriptionKind;
  /** What is the minimum subtotal to subscribe to this plan */
  minimumPrice: Scalars['Decimal'];
  name: Scalars['String'];
  /** All products allowed in this subscription plan. */
  products: Array<SubscriptionPlanProductType>;
};

/** An enumeration. */
export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Paused = 'PAUSED',
}

/** Subscription type. */
export type SubscriptionType = DeliverableInterface &
  Node &
  TotalizableInterface & {
    cancelOnNextCycle: Scalars['Boolean'];
    createdAt: Scalars['DateTime'];
    /** Discount for this item. */
    discount: Scalars['Decimal'];
    /** Freight for this item. */
    freight: Scalars['Decimal'];
    /** How many business days to deliver. */
    freightDays: Scalars['Int'];
    freightKind?: Maybe<ShippingKind>;
    /** The ID of the object */
    id: Scalars['ID'];
    /** Items for this subscription */
    items: Array<SubscriptionItemType>;
    /** The kind of the plan */
    kind?: Maybe<SubscriptionKind>;
    /** When the next order will be charged considering the paused status. */
    nextCharge?: Maybe<Scalars['Date']>;
    nextCycle: Scalars['Date'];
    nextCycleChange?: Maybe<Scalars['Date']>;
    /** When the next order will be generated considering the paused status. */
    nextDate?: Maybe<Scalars['Date']>;
    pausedForCycles: Scalars['Int'];
    /** Items for this subscription */
    pendingInvoices: Array<InvoiceType>;
    status?: Maybe<SubscriptionStatus>;
    /** Subtotal for this item. */
    subtotal: Scalars['Decimal'];
    /** Total (subtotal - discount) for this item. */
    total: Scalars['Decimal'];
    updatedAt: Scalars['DateTime'];
    user: UserType;
  };

export type SubscriptionUndoCancelMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "SubscriptionType" to mutate */
  id: Scalars['ID'];
};

/** Change the subscription next cycle. */
export type SubscriptionUndoCancelMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  subscription?: Maybe<SubscriptionType>;
};

/** Testimonial type. */
export type TestimonialType = Node & {
  /** The ID of the object */
  id: Scalars['ID'];
  /** The image of testimonial. */
  image?: Maybe<ImageType>;
  subtitle?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  title: Scalars['String'];
};

export type TicketCreateMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** A description for the ticket. */
  description: Scalars['String'];
  /** An optional object for the event. */
  obj?: InputMaybe<Scalars['ID']>;
};

/** Create a new ticket. */
export type TicketCreateMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The newly created ticket. */
  ticket?: Maybe<TicketType>;
};

/** An enumeration. */
export enum TicketStatus {
  Pending = 'PENDING',
  Resolved = 'RESOLVED',
}

/** Ticket type. */
export type TicketType = Node & {
  description: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  /** The object itself, which might be one of options in the union. */
  obj: EventLogObjectUnion;
  objId?: Maybe<Scalars['Int']>;
  resolvedAt: Scalars['DateTime'];
  resolvedBy?: Maybe<UserType>;
  status: TicketStatus;
  user?: Maybe<UserType>;
};

/** Interface for totalizable objects. */
export type TotalizableInterface = {
  /** Discount for this item. */
  discount: Scalars['Decimal'];
  /** Subtotal for this item. */
  subtotal: Scalars['Decimal'];
  /** Total (subtotal - discount) for this item. */
  total: Scalars['Decimal'];
};

export type UserAddressCreateMutationInput = {
  /** The cart id to associate with a lead event. Should be sent only when this is being executed in a checkout process */
  cart?: InputMaybe<Scalars['ID']>;
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  isBilling?: InputMaybe<Scalars['Boolean']>;
  isShipping?: InputMaybe<Scalars['Boolean']>;
  nickname?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  user: Scalars['ID'];
};

/** Create a new address for a user. */
export type UserAddressCreateMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  userAddress?: Maybe<UserAddressType>;
};

export type UserAddressDeleteMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "UserAddressType" to mutate */
  id: Scalars['ID'];
};

/** Create a new address for a user. */
export type UserAddressDeleteMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  userAddress?: Maybe<UserAddressType>;
};

export type UserAddressShippingSimulationMutationInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID of the "UserAddressType" to mutate */
  id: Scalars['ID'];
};

/** Simulate the new prices when changing the shipping address. */
export type UserAddressShippingSimulationMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The freight value */
  newFreight?: Maybe<Scalars['Decimal']>;
  /** The freight value */
  oldFreight?: Maybe<Scalars['Decimal']>;
  /** The mutated object. */
  userAddress?: Maybe<UserAddressType>;
};

/** Address type. */
export type UserAddressType = AddressInterface &
  Node & {
    /** The address's state (e.g. `Ribeirão Preto`) */
    city: Scalars['String'];
    /** An optional complement (e.g. `Apto 69 (nice)`) */
    complement?: Maybe<Scalars['String']>;
    /** The address's district (e.g. `Jardim Satânico`) */
    district: Scalars['String'];
    /** The ID of the object */
    id: Scalars['ID'];
    isBilling: Scalars['Boolean'];
    isShipping: Scalars['Boolean'];
    /** An optional nickname for the address. */
    nickname?: Maybe<Scalars['String']>;
    /** The nickname for this address. */
    postalCode: Scalars['String'];
    /** The address's state (e.g. `SP`) */
    state: Scalars['String'];
    /** The address's street (e.g. `Rua do Capeta`) */
    street: Scalars['String'];
    /** The address's street number (e.g. `666`) */
    streetNumber: Scalars['String'];
  };

export type UserAddressUpdateMutationInput = {
  city?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  /** ID of the "UserAddressType" to mutate */
  id: Scalars['ID'];
  isBilling?: InputMaybe<Scalars['Boolean']>;
  isShipping?: InputMaybe<Scalars['Boolean']>;
  nickname?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

/** Create a new address for a user. */
export type UserAddressUpdateMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  userAddress?: Maybe<UserAddressType>;
};

/** An enumeration. */
export enum UserGender {
  Agender = 'AGENDER',
  Androgyne = 'ANDROGYNE',
  Bigender = 'BIGENDER',
  Cisgender = 'CISGENDER',
  CisgenderFemale = 'CISGENDER_FEMALE',
  CisgenderMale = 'CISGENDER_MALE',
  Female = 'FEMALE',
  FemaleToMale = 'FEMALE_TO_MALE',
  Genderqueer = 'GENDERQUEER',
  GenderFluid = 'GENDER_FLUID',
  GenderNonconforming = 'GENDER_NONCONFORMING',
  GenderQuestioning = 'GENDER_QUESTIONING',
  GenderVariant = 'GENDER_VARIANT',
  Intersex = 'INTERSEX',
  Male = 'MALE',
  MaleToFemale = 'MALE_TO_FEMALE',
  Neither = 'NEITHER',
  Neutrois = 'NEUTROIS',
  NonBinary = 'NON_BINARY',
  Other = 'OTHER',
  Pangender = 'PANGENDER',
  Trans = 'TRANS',
  Transfeminine = 'TRANSFEMININE',
  Transgender = 'TRANSGENDER',
  TransgenderFemale = 'TRANSGENDER_FEMALE',
  TransgenderMale = 'TRANSGENDER_MALE',
  Transmasculine = 'TRANSMASCULINE',
  Transsexual = 'TRANSSEXUAL',
  TranssexualFemale = 'TRANSSEXUAL_FEMALE',
  TranssexualMale = 'TRANSSEXUAL_MALE',
  TransA = 'TRANS_A',
  TwoSpirit = 'TWO_SPIRIT',
}

export type UserLoginByEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  doc?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  /** reCAPTCHA token */
  token: Scalars['String'];
};

export type UserLoginByEmailPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
};

export type UserLoginValidateInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type UserLoginValidatePayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** If the operation succeeded. */
  success?: Maybe<Scalars['Boolean']>;
};

/** User payment method type. */
export type UserPaymentMethodType = Node & {
  address?: Maybe<AddressType>;
  brand: Scalars['String'];
  default: Scalars['Boolean'];
  description: Scalars['String'];
  displayNumber: Scalars['String'];
  gateway: GatewayChoice;
  holderName: Scalars['String'];
  /** The ID of the object */
  id: Scalars['ID'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

/** A user in the database. */
export type UserType = Node & {
  /** All addresses for this user. */
  addresses: Array<UserAddressType>;
  /** The image avatar of user. */
  avatar?: Maybe<ImageType>;
  birthday?: Maybe<Scalars['Date']>;
  cityRegistration?: Maybe<Scalars['String']>;
  /** User's current subscription. */
  currentSubscription?: Maybe<SubscriptionType>;
  dateJoined: Scalars['DateTime'];
  doc?: Maybe<Scalars['String']>;
  /** The user's primary email. */
  email?: Maybe<Scalars['String']>;
  fancyName?: Maybe<Scalars['String']>;
  /** User's full name. */
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<UserGender>;
  /** The ID of the object */
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  /** If this user is a local delivered, i.e. can deliver local packages. */
  isLocalDeliverer?: Maybe<Scalars['Boolean']>;
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** If the user is a subscriber (has an active subscription). */
  isSubscriber: Scalars['Boolean'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  kind?: Maybe<PersonKind>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  mgmCode: Scalars['String'];
  /** User's payment methods. */
  paymentMethods: Array<UserPaymentMethodType>;
  phone?: Maybe<Scalars['String']>;
  /** Total score for this user. */
  scoreTotal: Scalars['Int'];
  stateRegistration?: Maybe<Scalars['String']>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
};

export type UserUpdateMutationInput = {
  avatar?: InputMaybe<Scalars['UploadType']>;
  birthday?: InputMaybe<Scalars['Date']>;
  cityRegistration?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  doc?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fancyName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<UserGender>;
  /** ID of the "UserType" to mutate */
  id: Scalars['ID'];
  kind?: InputMaybe<PersonKind>;
  phone?: InputMaybe<Scalars['String']>;
  stateRegistration?: InputMaybe<Scalars['String']>;
};

/** Update a user's information. */
export type UserUpdateMutationPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** The mutated object. */
  user?: Maybe<UserType>;
};

/** Variety type. */
export type VarietyType = Node & {
  /** The ID of the object */
  id: Scalars['ID'];
  /** The variety image. */
  image?: Maybe<ImageType>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type VerifyInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type VerifyPayload = {
  clientMutationId?: Maybe<Scalars['String']>;
  payload: Scalars['GenericScalar'];
};
