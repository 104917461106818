/* eslint-disable no-console */
import { ApolloLink } from '@apollo/client';

const isSSR = typeof window === 'undefined';

export const apolloLinkLog = new ApolloLink((operation, forward) => {
  const group = console.groupCollapsed != null ? console.groupCollapsed : console.log;
  group('APOLLO LINK LOG::OPERATION', operation.operationName);
  console.log(operation.query.loc?.source.body);
  console.log(isSSR ? JSON.stringify(operation.variables) : operation.variables);
  if (console.groupCollapsed != null) {
    console.groupEnd();
  }
  return forward(operation).map(data => {
    group('APOLLO LINK LOG::RESULT', operation.operationName);
    console.log(isSSR ? JSON.stringify(data) : data);
    if (console.groupCollapsed != null) {
      console.groupEnd();
    }
    return data;
  });
});
