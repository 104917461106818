import { TransitionProps } from '@mui/material/transitions/transition';
import { Slide, SnackbarContent, Snackbar as MUISnackbar, Box } from '@mui/material';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';

const SlideTransition = (props: TransitionProps) => {
  return <Slide {...(props as any)} direction="up" />;
};

interface OwnProps {
  open: boolean;
  autoHideDuration?: number;
  message: string | ReactNode;
  type?: 'default' | 'success' | 'information' | 'danger';
  anchorOrigin?: {
    horizontal: 'center' | 'left' | 'right';
    vertical: 'bottom' | 'top';
  };
  onClose: () => void;
}

const SnackbarContainer = styled.div`
  &[data-content-type='success'] .MuiSnackbarContent-root {
    background-color: #4caf50 !important;
  }
  &[data-content-type='information'] .MuiSnackbarContent-root {
    background-color: #12b9b0 !important;
  }
  &[data-content-type='danger'] .MuiSnackbarContent-root {
    background-color: #ff4444 !important;
  }
  .MuiSnackbarContent-root {
    background-color: rgb(49, 49, 49) !important;
  }
`;

const snackIcon = {
  default: undefined,
  success: <DoneIcon />,
  information: undefined,
  danger: <ErrorIcon />,
};

export const Snackbar: FC<OwnProps> = ({
  open,
  autoHideDuration = 5000,
  message,
  type = 'default',
  anchorOrigin = { vertical: 'bottom', horizontal: 'center' },

  onClose,
}) => (
  <SnackbarContainer data-content-type={type}>
    <MUISnackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      TransitionComponent={SlideTransition}
    >
      <SnackbarContent
        message={
          typeof message === 'string' ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box mr={1}>{snackIcon[type]}</Box>
              {message}
            </Box>
          ) : (
            message
          )
        }
        data-e2e="snackbar-content"
      />
    </MUISnackbar>
  </SnackbarContainer>
);
