export const DEFAULT_ORIGIN = 'http://localhost:8050';
export const SSR_ORIGIN = 'http://back:8000';

export const getAPIEndpoint = () => {
  const isSSR = typeof window === 'undefined';
  const isProduction =
    process.env.NODE_ENV === 'production' && (process.env as any).IS_TEST !== '1';
  const origin = !isSSR ? window.location.origin : isProduction ? SSR_ORIGIN : DEFAULT_ORIGIN;
  return `${origin}/api/graphql/`;
};
