import { FC, useEffect, useState } from 'react';
import { emitter } from '@web/common/show-snackbar';
import { Snackbar } from './feedback/snackbar';

export const GeneralSnackbar: FC = () => {
  const [options, setOptions] = useState<any>(null);

  useEffect(() => {
    const onShow = (newOptions: any) => {
      setOptions(newOptions);
    };
    emitter.on('show', onShow);

    return () => {
      emitter.off('show', onShow);
    };
  }, []);

  return (
    <Snackbar
      autoHideDuration={15000}
      {...options}
      open={Boolean(options)}
      onClose={() => {
        setOptions(null);
        if (options && options.onClose) {
          options.onClose();
        }
      }}
    />
  );
};
