/* eslint-disable @typescript-eslint/brace-style */
import EventEmitter, { Emitter } from 'event-emitter';
import { captureException } from '../sentry';

export interface StorageBaseObjectItemMethods<T extends Record<string, unknown>> {
  get(): T | null;
  set(ops: T): void;
  hasKey(key: string, obj: T): void;
  addOrUpdateKey(key: string, value: unknown): void;
  removeKey(key: string): void;
}

type Maybe<T> = T | undefined;

export type StorageChangeHandler<T> = (cart: T | null) => void;

export class StorageBaseObjectItem<T extends Record<string, unknown>>
  implements StorageBaseObjectItemMethods<T>
{
  storageKey: string;

  storage: Maybe<Storage>;

  private emitter: Emitter = EventEmitter();

  private onChangeEvent: string;

  constructor(key: string, storage?: Storage) {
    this.storageKey = key;
    this.storage = storage;
    this.onChangeEvent = `${this.storageKey}OnChange`;
  }

  get(): T | null {
    if (this.storage != null) {
      const storageValue = this.storage.getItem(this.storageKey);
      if (typeof storageValue === 'string') {
        try {
          return JSON.parse(storageValue);
        } catch (e) {
          captureException(e);
        }
      }
    }
    return null;
  }

  set(ops: T) {
    if (this.storage != null) {
      this.storage.setItem(this.storageKey, JSON.stringify(ops));
      this.emitter.emit(this.onChangeEvent, ops);
    }
  }

  hasKey(key: string, obj = this.get()) {
    return obj != null && obj[key] != null;
  }

  addOrUpdateKey(key: string, value: unknown) {
    this.set({ ...this.get(), [key]: value } as T);
  }

  removeKey(key: string) {
    if (this.hasKey(key)) {
      const obj = this.get();
      if (obj != null) {
        delete obj[key];
        this.set(obj);
      }
    }
  }

  onChange(callback: StorageChangeHandler<T>) {
    this.emitter.on(this.onChangeEvent, callback);
    callback(this.get());
  }

  offChange(callback: StorageChangeHandler<T>) {
    this.emitter.off(this.onChangeEvent, callback);
  }
}
