import * as Sentry from '@sentry/nextjs';
import { GetServerSidePropsContext } from 'next';
import { useEffect } from 'react';

interface SentryExtra {
  query?: any;
  asPath?: string;
  pageProps?: any;
}

interface UseSentryOptions {
  me?: any;
  extras?: SentryExtra;
}

export const useSentryScope = (options?: UseSentryOptions) => {
  useEffect(() => {
    Sentry.configureScope(scope => {
      if (options?.me != null) {
        scope.setUser({
          email: options?.me.email,
          id: options?.me.id,
          username: options?.me.email,
        });
      }
      if (options?.extras != null) {
        scope.setExtras(options?.extras as any);
      }
    });
  });
};

export const sentryScope = (ctx: GetServerSidePropsContext, me?: any) => {
  Sentry.configureScope(scope => {
    if (me != null) {
      scope.setUser({
        email: me.email,
        id: me.id,
        username: me.email,
      });
    }
    scope.setExtras({
      query: ctx.query,
      params: ctx.params,
      resolvedUrl: (ctx as any).resolvedUrl,
      path: ctx.req?.url,
    });
  });
};

export const captureException = (e: unknown) => {
  Sentry.captureException(e);
};
