/* eslint-disable class-methods-use-this */
import { OrderItemFieldsFragment } from 'core/lib/graphql/fragments/_gen/order-item-fields.fragment';
import { ProductFieldsFragment } from 'core/lib/graphql/store/_gen/product';
import { PaymentMethod } from 'core/lib/graphql/types.d';
import { CartProductItemType } from '../components/my-cart-types';
import { GTagEvent, ShippingTier } from './google-tagmanager-event';
import { PixelEvent } from './pixel-event';

export class ExternalEventsFacade {
  static instance: ExternalEventsFacade;

  static getInstance() {
    if (this.instance == null) {
      this.instance = new ExternalEventsFacade();
    }
    return this.instance;
  }

  pixel: PixelEvent;

  constructor() {
    this.pixel = new PixelEvent();
  }

  login(method?: string) {
    GTagEvent.login(method);
  }

  signUp(method?: string) {
    GTagEvent.signUp(method);
  }

  share(method?: string) {
    GTagEvent.share(method);
  }

  search(term: string) {
    GTagEvent.search(term);
  }

  searchResults(term: string) {
    GTagEvent.searchResults(term);
  }

  viewProductCategory(
    listName: string,
    listId: string,
    products: ProductFieldsFragment[],
    coupon?: string,
  ) {
    GTagEvent.viewItemList(listName, listId, products, coupon);
  }

  viewProduct(product: ProductFieldsFragment, coupon?: string) {
    GTagEvent.viewItem(product, coupon);
    PixelEvent.viewProductDetail(product);
  }

  static addToCart(
    item: ProductFieldsFragment,
    quantity: number,
    gridId?: string,
    coupon?: string,
  ) {
    GTagEvent.addToCart(item, quantity, gridId, coupon);
  }

  static removeFromCart(item: OrderItemFieldsFragment, coupon?: string) {
    GTagEvent.removeFromCart(item, coupon);
  }

  lead(price: number) {
    GTagEvent.lead(price);
    PixelEvent.lead(price);
  }

  beginCheckout(value: number, items: OrderItemFieldsFragment[], coupon?: string | null) {
    GTagEvent.beginCheckout(value, items, coupon ?? undefined);
  }

  checkoutStep(step?: number, stepName?: string) {
    GTagEvent.checkoutStep(step, stepName);
  }

  static addShipping(
    value: number,
    items: CartProductItemType[],
    shippingTier?: ShippingTier,
    coupon?: string | null,
  ) {
    GTagEvent.shippingInfo(value, items, shippingTier, coupon ?? undefined);
  }

  // eslint-disable-next-line max-params
  addPaymentInfo(
    value: number,
    items: CartProductItemType[],
    paymentType?: PaymentMethod,
    coupon?: string | null,
  ) {
    GTagEvent.paymentInfo(value, items, paymentType, coupon ?? undefined);
  }

  // eslint-disable-next-line max-params
  purchase(
    value: number,
    items: OrderItemFieldsFragment[],
    transactionId: string,
    shippingPrice?: number,
    coupon?: string | null,
    subscription?: boolean,
  ) {
    GTagEvent.purchase(
      value,
      items,
      transactionId,
      shippingPrice,
      coupon ?? undefined,
      subscription,
    );
  }

  static cookieLoad(analytics: boolean, marketing: boolean) {
    if (analytics) {
      GTagEvent?.loadAnalytics();
    }
    if (marketing) {
      GTagEvent?.loadMarketing();
    }
  }
}

export const externalEvents = ExternalEventsFacade.getInstance();
