/* eslint-disable max-len */
import { ProductFieldsFragment } from 'core/lib/graphql/store/_gen/product';
import { ProductKind } from 'core/lib/graphql/types.d';
import { isEventsEnabled } from '../constants/events';
import { captureException } from '../sentry';

export interface PixelExtraContent {
  id: string;
  quantity: number;
  [key: string]: unknown;
}

/** For more info check:
 * https://developers.facebook.com/docs/facebook-pixel/reference#standard-events */
export interface PixelExtra {
  /** Category of the page/product. */
  content_category: string;
  /** Product IDs associated with the event, such as SKUs (e.g. `['ABC123', 'XYZ789']`). */
  content_ids: (number | string)[];
  /** Name of the page/product. */
  content_name: string;
  /** Either `product` or `product_group` based on the `content_ids` or `contents` being passed.
   * If the IDs being passed in `content_ids` or `contents` parameter are IDs of products then
   * the value should be `product`.
   * If product group IDs are being passed, then the value should be `product_group`. */
  content_type: 'product' | 'product_group';
  /** An array of JSON objects that contains the quantity and the
   * International Article Number (EAN) when applicable, or other product or content identifier(s).
   * `id` and `quantity` are the required fields.
   * e.g. `[{'id': 'ABC123', 'quantity': 2}, {'id': 'XYZ789', 'quantity': 2}]`. */
  contents: PixelExtraContent[];
  /** The currency for the `value` specified. */
  currency: string;
  /** Used with `InitiateCheckout` event. The number of items when checkout was initiated. */
  num_items: number;
  /** Predicted lifetime value of a subscriber as defined by the advertiser and expressed
   * as an exact value. */
  predicted_ltv: number | string;
  /** Used with the `Search` event. The string entered by the user for the search. */
  search_string: string;
  /** Used with the `CompleteRegistration` event, to show the status of the registration. */
  status: boolean;
  /** The value of a user performing this event to the business. */
  value: number;
  subscription_id?: string;
}

type PixelExtraViewContent = Partial<
  Pick<PixelExtra, 'content_category' | 'content_name' | 'currency' | 'value'>
> &
  Pick<PixelExtra, 'content_type' | 'content_ids' | 'contents'>;

const productToContents = (
  product: ProductFieldsFragment,
  quantity: number,
  gridId?: string,
): PixelExtraContent => ({
  id: product.id,
  clubPrice: product.clubPrice,
  originalPrice: product.originalPrice,
  price: product.price,
  coffeeKind: product.coffeeKind,
  kind: product.kind,
  name: product.name,
  producer: {
    id: product.producer?.id,
    name: product.producer?.name,
  },
  quantity,
  gridId,
});

export class PixelEvent {
  static fbq(event: string, eventType: string, extra?: unknown) {
    if (isEventsEnabled && typeof window !== 'undefined') {
      if (window.fbq != null) {
        try {
          window.fbq(event, eventType, extra);
        } catch (e) {
          captureException(e);
          // eslint-disable-next-line
          console.warn('Something went wrong with pixel');
        }
      }
    } else {
      // eslint-disable-next-line
      console.log('[PIXEL]', event, eventType, extra);
    }
  }

  static track<T extends object = PixelExtra>(category: string, extra?: T) {
    PixelEvent.fbq('track', category, extra);
  }

  /** For more info check:
   * https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
   * Custom event names must be strings, and cannot exceed 50 characters in length.
   */
  static trackCustom(category: string, extra?: Record<string, unknown>) {
    PixelEvent.fbq('trackCustom', category, extra);
  }

  // Whenever a users opens any page
  static viewContent(extra: PixelExtraViewContent) {
    PixelEvent.track<PixelExtraViewContent>('ViewContent', extra);
  }

  static viewProductDetail(item: ProductFieldsFragment) {
    PixelEvent.viewContent({
      content_ids: [item.id],
      content_type: 'product',
      contents: [productToContents(item, 1)],
      currency: 'BRL',
      value: item.price,
      content_name: item.name,
      content_category:
        item.kind === ProductKind.Coffee ? `${item.kind} - ${item.coffeeKind ?? ''}` : item.kind,
    });
  }

  // Whenever a user sends the first subscription information
  static lead(value?: number) {
    PixelEvent.track('Lead', { currency: 'BRL', value });
  }

  static customizeProduct(item: ProductFieldsFragment, gridId: string) {
    PixelEvent.track<Partial<PixelExtra>>('CustomizeProduct', {
      content_ids: [item.id],
      content_type: 'product',
      content_name: item.name,
      content_category:
        item.kind === ProductKind.Coffee ? `${item.kind} - ${item.coffeeKind ?? ''}` : item.kind,
      contents: [productToContents(item, 1, gridId)],
    });
  }
}
