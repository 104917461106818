// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const origin = typeof window !== 'undefined' ? window.location.origin : '';
const url = new URL(origin);
let environment = process.env.NODE_ENV;
if (origin.includes('staging')) environment = 'staging';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  environment,
  dsn: 'https://ced2b8f88d6d48a98072f1776a370988@sentry.blb.ventures/3',
  ignoreErrors: [
    'top.GLOBALS',
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
  ],
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [url.hostname],
      traceFetch: false,
      traceXHR: false,
    }),
  ],
  tracesSampleRate: 0.4,
  initialScope: {
    tags: { ssr: 'false' },
  },
});
