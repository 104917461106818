import { DefaultSeo, SocialProfileJsonLd } from 'next-seo';
import { Twitter } from 'next-seo/lib/types';
import Head from 'next/head';
import { FC } from 'react';
import { instagramUrl } from '../../constants/external-urls';
import {
  seoDescription,
  extraMeta,
  seoOpenGraph,
  seoSiteName,
  seoTitle,
  seoUrl,
} from './constants';

const sameAs: string[] = ['https://www.facebook.com/veroocafes/', instagramUrl];

const twitter: Twitter = {
  cardType: 'summary',
};

export const DefaultSEO: FC = () => (
  <>
    <DefaultSeo
      title={seoTitle}
      description={seoDescription}
      openGraph={seoOpenGraph}
      twitter={twitter}
      additionalMetaTags={extraMeta}
    />
    <SocialProfileJsonLd type="Organization" name={seoSiteName} url={seoUrl} sameAs={sameAs} />
    <Head>
      <link rel="apple-touch-icon" sizes="57x57" href="/favicons/apple-touch-icon.png" />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/favicons/android-chrome-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="512x512"
        href="/favicons/android-chrome-512x512.png"
      />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
      <link rel="manifest" href="/favicons/site.webmanifest" />
      <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#FFF" />
    </Head>
  </>
);
