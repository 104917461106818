type FieldMap = Record<string, string>;
type FieldsMap = Record<string, FieldMap>;

interface TypeMap {
  serialize(parsed: any): number | string | boolean | null;
  parseValue(raw: number | string | boolean | null): any;
}

type TypesMap = Record<string, TypeMap>;

export const apolloFormatter = (
  state: Record<string, any>,
  fieldsMap: FieldsMap,
  typesMap: TypesMap,
) =>
  Object.keys(state).reduce((acc, key) => {
    if (key.includes('Type:') && fieldsMap.hasOwnProperty(key.split(':')[0])) {
      const stateType = key.split(':')[0];
      acc[key] = Object.keys(state[key]).reduce((obj, fieldKey) => {
        const fieldType = fieldsMap[stateType][fieldKey];
        return {
          ...obj,
          [fieldKey]: Object.prototype.hasOwnProperty.call(typesMap, fieldType)
            ? typesMap[fieldType].parseValue(state[key][fieldKey])
            : state[key][fieldKey],
        };
      }, {} as Record<string, any>);
    } else {
      acc[key] = state[key];
    }
    return acc;
  }, {} as Record<string, any>);
